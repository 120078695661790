import {
    faBellConcierge,
    faBookUser,
    faBullhorn,
    faCalendarDays,
    faChartLineUp,
    faCreditCard,
    faGear,
    faList,
    faQuestionCircle,
    faTag,
    faUsers
} from '@fortawesome/pro-light-svg-icons';
import { ACCESS_LEVEL_COLLABORATOR_ID, OWNER_ID } from '@spike/model';
import { MenuLink } from 'model/Menu';
import { useSelector } from 'react-redux';
import { MenuState } from 'reducers/menu/MenuState';
import {
    AgreementsPath,
    BookingsPath,
    BusinessSettingsPath,
    CalendarPath,
    ClientsPath,
    HelpSupportPath,
    InsightsPath,
    MarketingPath,
    PaymentsPath,
    ProductsPath,
    ServicesPath,
    StaffPath
} from 'routes/Paths';
import store, { RootState } from 'store';
import { isMarketingEnabled, isPaymentsEnabled } from 'utils/MarketingUtils';
import { hasAccessLevel } from './accessLevel';
import { hasPremiumAccess } from './premiumPlan';

const menuLinks: Array<MenuLink> = [
    {
        id: 'bookings',
        label: 'Bookings',
        icon: faBellConcierge,
        path: BookingsPath
    },
    {
        id: 'calendar',
        label: 'Calendar',
        icon: faCalendarDays,
        path: CalendarPath
    },
    {
        id: 'clients',
        label: 'Clients',
        icon: faBookUser,
        path: ClientsPath,
        childs: [
            {
                id: 'clients-list',
                label: 'Clients List',
                path: ClientsPath
            },
            {
                id: 'agreements',
                label: 'Agreements',
                path: AgreementsPath
            }
        ]
    },
    {
        id: 'staff',
        label: 'Staff',
        icon: faUsers,
        path: StaffPath
    },
    {
        id: 'services',
        label: 'Services',
        icon: faList,
        path: ServicesPath,
        childs: [
            {
                id: 'primary-services',
                label: 'Primary Services',
                path: ServicesPath
            },
            {
                id: 'addons',
                label: 'Add-ons',
                path: `${ServicesPath}#add_ons`
            }
        ]
    },
    {
        id: 'products',
        label: 'Products',
        icon: faTag,
        path: ProductsPath
    },
    {
        id: 'marketing',
        label: 'Marketing',
        icon: faBullhorn,
        path: MarketingPath,
        childs: [
            {
                id: 'sms-campaigns',
                label: 'SMS Campaigns',
                path: MarketingPath
            }
        ]
    },
    {
        id: 'insights',
        label: 'Insights',
        icon: faChartLineUp,
        path: InsightsPath,
        childs: [
            {
                id: 'insights-dashboard',
                label: 'Dashboard',
                path: InsightsPath
            },
            {
                id: 'insights-clients-pets',
                label: 'Clients & Pets',
                path: `${InsightsPath}#clients_pets`
            }
        ]
    },
    {
        id: 'payments',
        label: 'Payments',
        icon: faCreditCard,
        path: PaymentsPath,
        childs: [
            {
                id: 'dashboard',
                label: 'Dashboard',
                path: `${PaymentsPath}#DASHBOARD`
            },
            {
                id: 'new-sale',
                label: 'New Sale',
                path: `${PaymentsPath}#SINGLE_SALE`
            },
            {
                id: 'transactions',
                label: 'Transactions',
                path: `${PaymentsPath}#TRANSACTIONS`
            },
            {
                id: 'sales-reports',
                label: 'Sales Reports',
                path: `${PaymentsPath}#SALES_REPORTS`
            },
            {
                id: 'tips-details',
                label: 'Tips Details',
                path: `${PaymentsPath}#TIPS_DETAIL`
            },
            {
                id: 'commissions-details',
                label: 'Commissions',
                path: `${PaymentsPath}#COMMISSIONS`
            },
            {
                id: 'bank-accounts',
                label: 'Bank Accounts',
                path: `${PaymentsPath}#BANK_ACCOUNTS`
            },
            {
                id: 'terminals',
                label: 'Terminals',
                path: `${PaymentsPath}#TERMINALS`
            },
            {
                id: 'payouts',
                label: 'Payouts',
                path: `${PaymentsPath}#TRANSFERS`
            },
            {
                id: 'setup',
                label: 'Setup',
                path: `${PaymentsPath}#SETUP`
            }
        ]
    },
    {
        id: 'settings',
        label: 'Settings',
        icon: faGear,
        path: BusinessSettingsPath
    },
    {
        id: 'helpCenter',
        label: 'Help & Support',
        icon: faQuestionCircle,
        path: HelpSupportPath
    }
];

export const useMenu = () => {
    const menu = useSelector<RootState, MenuState>(state => state.menu);

    return menu;
};

export const useSidebarLinks = (): Array<MenuLink> => {
    return filterEnabledLinks(menuLinks).filter(link => !['settings', 'helpCenter'].includes(link.id));
};

export const useSidebarFooterLinks = (): Array<MenuLink> => {
    return filterEnabledLinks(menuLinks).filter(link => ['settings', 'helpCenter'].includes(link.id));
};

export const useMenuLinks = (): Array<MenuLink> => {
    return filterEnabledLinks(menuLinks);
};

const filterEnabledLinks = (links: Array<MenuLink>): Array<MenuLink> => {
    const user = store.getState().login.auth.user;
    const isOwner = user!.role.id === OWNER_ID;
    const timeZone = store.getState().login.auth.timeZone;
    const { subscriptions } = store.getState().subscriptions;
    const marketplace = store.getState().marketplace.marketplace;
    const masterData = store.getState().masterData.data;

    const paymentsEnabled = isPaymentsEnabled(marketplace);
    const marketingEnabled = isMarketingEnabled(marketplace.id);
    //const insightsEnabled = hasPremiumAccess(subscriptions, masterData.resourceAccessPlans, `${timeZone}`, 'insights');
    const insightsEnabled = isOwner && hasAccessLevel('insights', masterData, user);

    return links
        .filter(item => item.id !== 'marketing' || marketingEnabled)
        .filter(item => item.id !== 'payments' || paymentsEnabled)
        .filter(item => item.id !== 'insights' || insightsEnabled)
        .filter(item => !item.exclusiveRoleIds || item.exclusiveRoleIds?.includes(user?.role.id || -1))
        .filter(item => hasAccessLevel(item.id, masterData, user));
};
