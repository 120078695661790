import { DayCellContentArg } from '@fullcalendar/core';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { MarketplaceScheduleType } from '@spike/marketplace-model';
import clsx from 'clsx';
import { PetsCountBadge } from 'components/MultiSlot/PetsCountBadge';
import { getStaffSlotsByDate } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { useMarketplace, useTimeZone } from 'hooks';
import useIsMobile from 'hooks/useIsMobile';
import Staff from 'model/Staff';
import moment from 'moment-timezone';
import React, { FunctionComponent } from 'react';

interface MonthDayCellFullCalendarProps extends DayCellContentArg {
    staffs: Array<Staff>;
    appointmentsCount: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                height: '90px'
            },
            [theme.breakpoints.only('md')]: {
                height: '110px'
            },
            [theme.breakpoints.only('lg')]: {
                height: '120px'
            },
            [theme.breakpoints.only('xl')]: {
                height: '140px'
            }
        },
        header: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '100px',
            width: '25px',
            height: '25px'
        },
        today: {
            backgroundColor: '#BDD2CA'
        },
        date: {
            fontFamily: 'Poppins',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '14px'
            }
        },
        body: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            flexGrow: 1
            //height: '100%',
            //border: '1px solid blue',
        },
        count: {
            fontFamily: 'Poppins',
            fontWeight: 600,
            [theme.breakpoints.down('sm')]: {
                fontSize: '13px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '19px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '23px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '32px'
            }
        },
        appointments: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
                fontSize: '7px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '16px'
            }
        },
        petsCountBadge: {
            marginTop: 4
        }
    })
);

export const MonthDayCellFullCalendar: FunctionComponent<MonthDayCellFullCalendarProps> = props => {
    const classes = useStyles();
    const isMobile = useIsMobile();
    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const date = moment(props.date).tz(timeZone);

    const maxSlots = props.staffs.reduce((acc, staff) => {
        const staffslots = getStaffSlotsByDate(staff.slots, date).map(slot => slot.petsCount);

        return acc + staffslots.reduce((acc, slot) => acc + slot, 0);
    }, 0);

    return (
        <Box className={classes.container}>
            <Box
                id={`calendar_div_month_day_${props.date.getDate()}_${props.date.getMonth()}`}
                className={clsx(classes.header, {
                    [classes.today]: props.isToday
                })}
            >
                <Typography className={classes.date}>{props.date.getDate()}</Typography>
            </Box>
            {props.appointmentsCount > 0 && (
                <Box className={classes.body}>
                    <Typography className={classes.count}>{props.appointmentsCount}</Typography>
                    <Typography className={classes.appointments}>{isMobile ? 'App.' : 'Appointments'}</Typography>

                    {marketplace.scheduleType === MarketplaceScheduleType.MultiSlots && (
                        <PetsCountBadge
                            maxPetsCount={maxSlots}
                            petsCount={props.appointmentsCount}
                            className={classes.petsCountBadge}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default MonthDayCellFullCalendar;
