import { Box, Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { MarketplaceBasics } from '@spike/marketplace-model';
import { FieldError } from '@spike/model';
import { isEmailValid, validatePhoneNumber, validateZipcode } from '@spike/validations';
import { useNonInitialEffect } from '@versiondos/hooks';
import clsx from 'clsx';
import { Button, PhoneField3, TextField2, ControlledZipCodeField3 } from 'components/UI';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { validateRequired } from 'utils/ValidationUtils';
import { BusinessSettingsTitle } from '../UI';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { formatBusinessId } from 'utils/BusinessIdUtils';

export interface Props {
    basics: MarketplaceBasics;
    businessId: number | undefined;
    onSave?: (basics: MarketplaceBasics) => void;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            },
            [theme.breakpoints.up('sm')]: {
                '& input': {
                    '&::placeholder': {
                        color: 'transparent'
                    }
                }
            },
            [theme.breakpoints.only('md')]: {
                width: '276px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '398px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '558px'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px'
            }
        },
        businessSettingsTittle: {
            'display': 'flex',
            'alignItems': 'center',
            '& $iconBusinessTitle': {
                fontSize: '14px'
            },
            [theme.breakpoints.down('sm')]: {
                'fontSize': '20px',
                'marginBottom': '5px',
                '& $iconBusinessTitle': {
                    fontSize: '20px',
                    fontWeight: '300'
                }
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px',
            fontWeight: 'lighter'
        },
        nameContainer: {
            display: 'flex',
            width: '100%',
            position: 'relative'
        },
        addressSuiteContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '14px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '22px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '29px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '44px'
            }
        },

        address: {
            display: 'flex',
            width: '70%',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '6px'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '13px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '19px'
            }
        },
        suite: {
            display: 'flex',
            width: '30%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        rowContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '9px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '20px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '31px'
            }
        },
        rowContainerFullMobile: {
            [theme.breakpoints.down('sm')]: {
                '::-webkit-input-placeholder"': {
                    color: 'transparent'
                },
                'flexWrap': 'wrap',
                '& $city': {
                    marginBottom: '10px'
                },
                '& $state': {
                    marginBottom: '10px'
                },
                '& $zipcode': {},
                '& $address': {
                    marginBottom: '10px',
                    width: '100%'
                },
                '& $suite': {}
            }
        },
        city: {
            display: 'flex',
            width: '33%',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '4px',
                width: '100%'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '8px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '12px'
            }
        },
        state: {
            display: 'flex',
            width: '33%',
            [theme.breakpoints.down('sm')]: {
                paddingRight: '4px',
                paddingLeft: '4px',
                width: '100%'
            },
            [theme.breakpoints.only('md')]: {
                paddingRight: '6px',
                paddingLeft: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingRight: '8px',
                paddingLeft: '8px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingRight: '12px',
                paddingLeft: '12px'
            }
        },
        zipcode: {
            display: 'flex',
            width: '33%',
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '4px',
                width: '100%'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '6px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '8px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '12px'
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            marginTop: '28px',
            [theme.breakpoints.down('sm')]: {
                marginTop: '27px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '44px'
            }
        },
        buttonContainerFull: {
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '18px'
                    }
                }
            }
        },
        defaultMobileInputContainer: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    width: '100%',
                    height: '26px',
                    fontSize: '16px'
                },
                '& fieldset': {
                    border: 'solid 1px #D3D3D3 !important'
                },
                '& input:focus + fieldset': {
                    borderColor: '#222 !important'
                },
                '& .standarInputResponsive .MuiBox-root': {
                    marginBottom: '3px'
                },
                '& .standarInputResponsive .MuiBox-root > p:first-child': {
                    fontSize: '14px',
                    fontWeight: 500
                }
            }
        },
        businessId: {
            fontSize: 14,
            marginLeft: 0,
            fontWeight: 400,
            color: '#969696',
            position: 'absolute',
            right: 0,
            top: 2
        }
    })
);

export const Basics: FunctionComponent<Props> = props => {
    const classes = useStyles();

    const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);

    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(false);

    const [basics, setBasics] = useState(cloneDeep(props.basics));
    const [errors, setErrors] = useState<Array<FieldError>>([]);

    useNonInitialEffect(() => {
        setSaved(isEqual(props.basics, basics));
    }, [basics]);

    useNonInitialEffect(() => {
        if (MarketplaceStatus.SaveSuccess === status) {
            setSaved(true);
            setLoading(false);
        }
    }, [status]);

    const cleanErrors = (fieldName: string) => {
        setErrors(prev => prev.filter(e => e.fieldName !== fieldName));
    };

    const validate = (basics: MarketplaceBasics) => {
        const errors: Array<FieldError> = [
            validateRequired('businessName', basics),
            validateRequired('address.street', basics, 'Address'),
            validateRequired('address.city', basics),
            validateRequired('address.state', basics),
            validateRequired('address.zipcode', basics),
            validateRequired('phone', basics),
            validateRequired('email', basics)
        ].filter((t): t is FieldError => !!t);

        errors.push(...validatePhoneNumber(basics.phone, basics.address.country!.id, 'phone'));

        if (!isEmailValid(basics.email)) {
            errors.push({
                fieldName: 'email',
                errorMessage: 'Invalid Email format.'
            });
        }

        !isEmpty(basics.address.zipcode) &&
            errors.push(...validateZipcode(basics.address.zipcode, basics.address.country?.id, 'address.zipcode'));

        return errors;
    };

    const changeValueHandler = (value: string, fieldName: string) => {
        cleanErrors(fieldName);

        const basicsAux: any = { ...basics };

        const attributes = fieldName.split('.');

        if (attributes.length === 1) {
            basicsAux[attributes[0]] = value;
        } else if (attributes.length === 2) {
            basicsAux[attributes[0]][attributes[1]] = value;
        }

        setBasics(basicsAux);
    };

    const saveHandler = () => {
        const validationErrors = validate({ ...basics });
        if (validationErrors.length === 0) {
            props.onSave && props.onSave({ ...basics });
            props.onSave && setLoading(true);
        }
        setErrors(validationErrors);
    };

    return (
        <Box className={clsx(classes.container, props.className, classes.defaultMobileInputContainer)}>
            <Box className={classes.titleContainer}>
                <BusinessSettingsTitle className={classes.businessSettingsTittle}>
                    {' '}
                    {props.isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={props.openSidebar}
                            icon={faArrowLeft}
                            size="1x"
                        />
                    )}{' '}
                    The Basics{' '}
                </BusinessSettingsTitle>
            </Box>
            <Box className={classes.nameContainer}>
                <TextField2
                    label="Business Name"
                    name="businessName"
                    value={basics.businessName}
                    onChange={changeValueHandler}
                    placeholder="Business Name"
                    errors={errors}
                    required={true}
                    className="standarInputResponsive"
                />
                {props.businessId && (
                    <Typography className={classes.businessId}>ID: {formatBusinessId(props.businessId)}</Typography>
                )}
            </Box>
            {/* <Box className={classes.nameContainer}>
        <TextField2
          label="Slug"
          name="slug"
          value={basics.slug || ''}
          onChange={changeValueHandler}
          errors={errors}
          required={true}
        />
      </Box> */}
            <Box className={clsx(classes.addressSuiteContainer, classes.rowContainerFullMobile)}>
                <Box className={classes.address}>
                    <TextField2
                        label="Address"
                        name="address.street"
                        value={basics.address.street}
                        onChange={changeValueHandler}
                        placeholder="Address"
                        errors={errors}
                        required={true}
                        className="standarInputResponsive"
                    />
                </Box>
                <Box className={classes.suite}>
                    <TextField2
                        label="Suite"
                        name="address.suite"
                        value={basics.address.suite || ''}
                        onChange={changeValueHandler}
                        placeholder="Suite"
                        errors={errors}
                        className="standarInputResponsive"
                    />
                </Box>
            </Box>
            <Box className={clsx(classes.rowContainer, classes.rowContainerFullMobile)}>
                <Box className={classes.city}>
                    <TextField2
                        label="City"
                        name="address.city"
                        value={basics.address.city}
                        onChange={changeValueHandler}
                        placeholder="City"
                        errors={errors}
                        capitalize={true}
                        required={true}
                        className="standarInputResponsive"
                    />
                </Box>
                <Box className={classes.state}>
                    <TextField2
                        label="State"
                        name="address.state"
                        value={basics.address.state}
                        onChange={changeValueHandler}
                        placeholder="State"
                        errors={errors}
                        capitalize={true}
                        required={true}
                        className="standarInputResponsive"
                    />
                </Box>
                <Box className={classes.zipcode}>
                    <ControlledZipCodeField3
                        label="ZIP Code"
                        name="address.zipcode"
                        value={basics.address.zipcode}
                        onChange={changeValueHandler}
                        placeholder="ZIP Code"
                        errors={errors}
                        required={true}
                        className="standarInputResponsive"
                    />
                </Box>
            </Box>
            <Box className={classes.rowContainer}>
                <PhoneField3
                    label="Phone Number"
                    name="phone"
                    value={basics.phone}
                    onChange={changeValueHandler}
                    placeholder="Phone Number"
                    errors={errors}
                    required={true}
                    className="standarInputResponsive"
                />
            </Box>
            <Box className={classes.rowContainer}>
                <TextField2
                    label="Email"
                    name="email"
                    value={basics.email}
                    onChange={changeValueHandler}
                    placeholder="Email"
                    errors={errors}
                    required={true}
                    className="standarInputResponsive"
                />
            </Box>
            <Box className={classes.rowContainer}>
                <TextField2
                    label="Website"
                    name="website"
                    value={basics.website}
                    onChange={changeValueHandler}
                    placeholder="Website"
                    errors={errors}
                    className="standarInputResponsive"
                />
            </Box>
            <Box className={clsx(classes.buttonContainer, classes.buttonContainerFull)}>
                <Button
                    label={saved ? 'Saved!' : 'Save'}
                    onClick={saveHandler}
                    loading={loading}
                />
            </Box>
        </Box>
    );
};

export default Basics;
