import React from 'react';
import { ResourceLabelContentArg } from '@fullcalendar/resource';
import { Avatar, Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { getStaffSlotsByDate } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { Moment } from 'moment-timezone';
import { useMarketplace } from 'hooks';
import { PetsCountBadge } from 'components/MultiSlot/PetsCountBadge';
import { MarketplaceScheduleType } from '@spike/marketplace-model';

export type ResourceHeaderFullCalendarProps = Omit<ResourceLabelContentArg, 'date'> & {
    date: Moment;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            gap: 8,
            display: 'flex',
            alignItems: 'center',
            padding: '8px 4px'
        },
        avatar: {
            [theme.breakpoints.down('sm')]: {
                width: '30px',
                height: '30px'
            },
            [theme.breakpoints.only('md')]: {
                width: '34px',
                height: '34px'
            },
            [theme.breakpoints.up('lg')]: {
                width: '42px',
                height: '42px'
            }
        },
        name: {
            fontWeight: 500,
            color: '#222222',
            fontFamily: 'Poppins',
            [theme.breakpoints.down('sm')]: {
                fontSize: '11px',
                lineHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '12px',
                lineHeight: '15px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '14px',
                lineHeight: '17px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '16px',
                lineHeight: '19px'
            }
        }
    })
);

export const ResourceHeaderFullCalendar: React.FC<ResourceHeaderFullCalendarProps> = props => {
    const classes = useStyles();

    const marketplace = useMarketplace();

    const maxPetsCount = React.useMemo(() => {
        return getStaffSlotsByDate(props.resource.extendedProps.slots, props.date).reduce((petsCount, slot) => {
            return petsCount + slot.petsCount;
        }, 0);
    }, [props.resource]);

    return (
        <Box className={classes.container}>
            <Avatar
                className={classes.avatar}
                src={props.resource.extendedProps.avatar}
            />
            <Typography className={classes.name}>
                {`${props.resource.extendedProps.firstName} ${props.resource.extendedProps.lastName}`}
            </Typography>

            {marketplace.scheduleType === MarketplaceScheduleType.MultiSlots && (
                <PetsCountBadge
                    maxPetsCount={maxPetsCount}
                    petsCount={props.resource.extendedProps.appointmentsCount}
                />
            )}
        </Box>
    );
};

export default ResourceHeaderFullCalendar;
