import * as amplitude from '@amplitude/analytics-browser';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { faFileArrowUp } from '@fortawesome/pro-light-svg-icons/faFileArrowUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, useMediaQuery } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Client from '@spike/client-model';
import { clearClientsThunk, ClientsStatus, fetchClientsThunk, searchClientsThunk } from '@spike/clients-action';
import { showErrorThunk } from '@spike/notifications-action';
import { clearPetsThunk } from '@spike/pets-action';
import { useNonInitialEffect } from '@versiondos/hooks';
import ApiClient from 'api';
import clsx from 'clsx';
import OnboardingTutorial from 'components/OnboardingTutorial';
import { Button, TextField2, ToolbarButton, ToolbarItem } from 'components/UI';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import ExportCSV from 'components/UI/ExportCSV/ExportCSV';
import { AMPLITUDE } from 'constants/index';
import { useApiClientWrapper, useMarketplace } from 'hooks';
import { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import ClientBulkUpload from './ClientBulkUpload';
import ClientsNotCreated from './ClientsNotCreated';
import ClientsNotFound from './ClientsNotFound';
import Header from './Header';
import Table from './Table';
import { refreshMarketplaceThunk } from '@spike/marketplace-action';

interface ClientProps {
    onAddClient?: () => void;
    onEditClient?: (clientId: number) => void;
    onEditPet?: (petid: number, clientId: number) => void;
}

interface BookingNew {
    clientId: number;
    petId: number;
    clientName: string;
}

const backendFieldSeparator = ';';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            backgroundColor: 'white'
        },
        onboardingContainer: {
            height: '100%'
        },
        bulkUploadContainerWrapper: {
            position: 'absolute',
            top: 0
        },
        bulkUploadContainer: {
            top: 0,
            left: 0,
            zIndex: 9999,
            width: '100%',
            height: '100%',
            position: 'fixed',
            backgroundColor: 'white'
        },
        overlayScroll: {
            height: '100%',
            width: '100%',
            [theme.breakpoints.only('lg')]: {
                width: '99,5%'
            }
        },
        table: {
            width: '100%'
        },
        buttonHeaderHide: {
            justifyContent: 'flex-start',
            alignItems: 'center',
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(25)}px`,
                paddingRight: `${reduceResolution(24)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '25px',
                paddingRight: '24px'
            }
        },
        clientsNotCreatedContainer: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '27px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '43px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '57px'
            },
            [theme.breakpoints.up('xl')]: {
                marginTop: '87px'
            }
        },
        input: {
            'borderRadius': 30,
            'background': '#FFFFFF !important',

            '& input': {
                '&::placeholder': {
                    opacity: 1,
                    color: '#7A7A7A'
                },

                [theme.breakpoints.down('sm')]: {
                    height: 47,
                    paddingTop: 0,
                    paddingBottom: 0,
                    fontSize: 14
                }
            },
            '& .MuiInputAdornment-root': {
                'opacity': 0.3,
                'marginRight': 0,
                'pointer-events': 'none',

                '& svg': {
                    cursor: 'default'
                },
                [theme.breakpoints.down('md')]: {
                    'opacity': 1,
                    '& svg': {
                        color: '#000',
                        fontWeight: 400
                    }
                }
            }
        },
        inputContainer: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '12px 11px',
            borderRadius: '12px',
            backgroundColor: '#F1F1F1',
            [theme.breakpoints.up('md')]: {
                paddingLeft: '22px',
                paddingRight: '22px',
                paddingBottom: '14px'
            }
        },
        inputBox: {},
        search: {
            [theme.breakpoints.down(wbp)]: {
                marginLeft: `${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginLeft: '5px'
            }
        },
        headerBoton: {},
        clickable: {
            cursor: 'pointer'
        },
        iconSearch: {
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(5)}px`,
                height: `${reduceResolution(18)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '5px',
                height: '18px'
            }
        },
        containerHeader: {
            paddingTop: 17,

            [theme.breakpoints.up('md')]: {
                paddingTop: '30px',
                backgroundColor: '#FAFAFA'
            }
        },
        containerButtonHeader: {
            display: 'flex',

            [theme.breakpoints.down('sm')]: {
                gap: 7,
                padding: '0px 16px',
                alignItems: 'center'
            },
            [theme.breakpoints.up('md')]: {
                paddingBottom: '18px'
            }
        },
        toolbarCenter: {
            fontSize: 18,
            marginTop: 'auto',
            marginRight: 20,
            alignItems: 'end',
            marginLeft: -20,

            [theme.breakpoints.down('sm')]: {
                display: 'none'
            },
            [theme.breakpoints.up('lg')]: {
                marginBottom: '8px'
            }
        },
        containerSearch: {
            paddingTop: 23,
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 21,

            [theme.breakpoints.up('md')]: {
                paddingTop: '20px',
                paddingLeft: '25px',
                paddingRight: '25px',
                borderRadius: '12px',
                paddingBottom: '18px'
            }
        },
        importMobileButton: {
            'width': 38,
            'height': 38,
            'color': '#fff',
            'flex': '0 0 38px',
            'padding': '0px',
            'minWidth': 'unset',
            'borderRadius': '50%',
            'alignItems': 'center',
            'display': 'inline-flex',
            'justifyContent': 'center',
            'backgroundColor': '#F1F1F1 !important',
            '& svg': {
                width: 16,
                height: 16,
                color: '#000000'
            },
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        csv: {
            'textDecoration': 'none',
            [theme.breakpoints.down('sm')]: {
                //width: "45%"
            },
            '& button': {
                [theme.breakpoints.down('sm')]: {
                    'height': 39,
                    'width': 39,
                    'alignItems': 'center',
                    'display': 'flex',
                    'justifyContent': 'center',

                    'marginLeft': 10,
                    '& svg': {
                        width: 16,
                        height: 16
                    }
                }
            }
        }
    })
);

const Clients: FunctionComponent<ClientProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapepr = useApiClientWrapper();
    const marketplace = useMarketplace();

    const csvLinkRef = useRef<any>(null);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

    const clientsStatus = useSelector<RootState, ClientsStatus>(state => state.clients.status);
    const clients = useSelector<RootState, Array<Client>>(state => state.clients.list);
    const page = useSelector<RootState, number>(state => Number(state.clients.page));
    const pageSize = useSelector<RootState, number>(state => Number(state.clients.size));
    const totalRecords = useSelector<RootState, number>(state =>
        state.clients.total_records ? state.clients.total_records : 0
    );
    const clientsDone = useSelector<RootState, boolean>(state => state.onboardingSteps.onboardingSteps.clientsDone);

    const totalPages = useSelector<RootState, number | undefined>(state => state.clients.total_pages);

    const onboardingPageParams = isMobile
        ? {
              title: 'Your Clients',
              buttonLabel: 'Add Client',
              image: 'images/onboarding/emptyscreen-clients-mobile.svg',
              imageWidth: 300,
              imageHeight: 500
          }
        : {
              title: 'Your Clients',
              buttonLabel: 'Add Client',
              image: 'images/onboarding/clients.svg',
              imageWidth: 1029.81,
              imageHeight: 630
          };

    const [loading, setLoading] = useState(true);
    const [isLastSearchAll, setIsLastSearchAll] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [showNewBooking, setShowNewBooking] = useState<BookingNew | undefined>(undefined);
    const [showSearch, setShowSearch] = useState(false);
    const [showBulkUpload, setShowBulkUpload] = useState(false);

    const [exporting, setExporting] = useState<{
        loading: boolean;
        headers: Array<string>;
        data: Array<Array<string>>;
    }>({ loading: false, headers: [], data: [] });

    useEffect(() => {
        console.log('clients', clients);
    }, [clients]);

    const fetchClients = (page: number, pageSize = 20) => {
        if (searchText.length === 0) {
            setIsLastSearchAll(true);
            dispatch(fetchClientsThunk(apiClientWrapepr, page, pageSize, true));
            setLoading(true);
        } else if (searchText.length >= 3) {
            setIsLastSearchAll(false);
            dispatch(searchClientsThunk(apiClientWrapepr, searchText, page, pageSize, true));
            setLoading(true);
        }
    };

    useEffect(() => {
        dispatch(refreshMarketplaceThunk(apiClientWrapepr));
    }, []);

    useEffect(() => {
        dispatch(clearPetsThunk());
        dispatch(clearClientsThunk());
        fetchClients(1, 20);
        return () => {
            dispatch(clearClientsThunk());
            dispatch(clearPetsThunk());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBulkUpload]);

    useNonInitialEffect(() => {
        console.log('clientsStatus', clientsStatus);
        switch (clientsStatus) {
            case ClientsStatus.FetchSuccess:
                setLoading(false);
                break;
            case ClientsStatus.Error:
                setLoading(false);
        }
    }, [clientsStatus]);

    useNonInitialEffect(() => {
        fetchClients(page, pageSize);
    }, [searchText]);

    useNonInitialEffect(() => {
        if (!exporting.loading) {
            csvLinkRef.current?.export();
        }
    }, [exporting.data]);

    const changePageHandler = (page: number) => {
        fetchClients(page, pageSize);
    };

    const changePageSizeHandler = (pageSize: number) => {
        fetchClients(page, pageSize);
    };

    const backNewBookingHandler = () => {
        setShowNewBooking(undefined);
    };

    const saveNewBookingHandler = () => {
        setShowNewBooking(undefined);
    };

    const handleClickSearch = (): void => {
        setSearchText('');
        setShowSearch(!showSearch);
    };

    const bookHandler = (client: Client) => {
        amplitude.track(AMPLITUDE.CTA_BOOK);
        const pet = client.pets.filter(pet => pet.active && !pet.deceased)[0];
        pet &&
            setShowNewBooking({
                clientId: client.id ?? 0,
                clientName: `${client.firstName} ${client.lastName}`,
                petId: pet.id
            });
    };

    const exportHandler = () => {
        setExporting(prev => ({
            ...prev,
            loading: true,
            headers: [],
            data: []
        }));

        ApiClient.get<Array<string>>(
            `/export_data?marketplace_id=${apiClientWrapepr.marketplaceId}`,
            apiClientWrapepr.requestConfig
        )
            .then(response => {
                const headers: Array<string> = response.data[0].split(backendFieldSeparator);

                const data: Array<Array<string>> = response.data.slice(1).map(row => row.split(backendFieldSeparator));

                setExporting(prev => ({
                    ...prev,
                    loading: false,
                    headers: [...headers],
                    data: [...data]
                }));
            })
            .catch(() => {
                dispatch(showErrorThunk('Error exporting.'));
                setExporting(prev => ({ ...prev, loading: false }));
            });
    };

    const searchIcon = (
        <FontAwesomeIcon
            onClick={handleClickSearch}
            icon={faSearch}
            className={clsx(!showSearch ? classes.iconSearch : classes.search)}
        />
    );

    const onBoarding = (
        <Box className={classes.onboardingContainer}>
            <OnboardingTutorial
                id="clients_onboarding"
                titlePage={onboardingPageParams.title}
                addButtonLabel={onboardingPageParams.buttonLabel}
                onPressAdd={props.onAddClient}
                urlImage={onboardingPageParams.image}
                imageWidth={onboardingPageParams.imageWidth}
                imageHeight={onboardingPageParams.imageHeight}
                isMobile={isMobile}
            />
        </Box>
    );

    const header = (
        <Box className={clsx(classes.containerHeader)}>
            <Box className={clsx(classes.containerButtonHeader)}>
                <Header
                    title={`Clients List ${totalRecords === 0 ? '' : '(' + totalRecords + ')'}`}
                    buttonLabel={isMobile ? 'Add' : 'Add Client'}
                    onClick={props.onAddClient}
                    id="clients_list_add_button"
                />
                <Button
                    className={classes.importMobileButton}
                    label={<FontAwesomeIcon icon={faFileArrowUp} />}
                    onClick={() => setShowBulkUpload(true)}
                    loading={exporting.loading}
                />
                <ToolbarButton className={classes.toolbarCenter}>
                    <ToolbarItem
                        id="import_clients"
                        text="Import Clients"
                        icon={faFileArrowUp}
                        onClick={() => setShowBulkUpload(true)}
                    />
                </ToolbarButton>
            </Box>
            {!showSearch && (
                <Box className={clsx(classes.containerSearch)}>
                    <Box className={clsx(classes.inputContainer)}>
                        <Box className={clsx(classes.inputBox)}>
                            <TextField2
                                id="clients_search_by_client_or_pet_input"
                                placeholder="Search by client or pet"
                                className={classes.input}
                                value={searchText}
                                onChange={setSearchText}
                                startIcon={searchIcon}
                            />
                        </Box>
                        <ExportCSV
                            headers={exporting.headers}
                            records={exporting.data}
                            filename={`Client Information ${marketplace.basics.businessName}.csv`}
                            ref={csvLinkRef}
                            exporting={exporting.loading}
                            onClick={exportHandler}
                        />
                    </Box>
                </Box>
            )}
        </Box>
    );

    const table = (
        <Table
            page={clients}
            totalRecords={totalRecords}
            pageNumber={page}
            pageSize={pageSize}
            loading={loading}
            onChangePage={changePageHandler}
            onChangePageSize={changePageSizeHandler}
            className={classes.table}
            onEditClient={props.onEditClient}
            onEditPet={props.onEditPet}
            onBook={bookHandler}
            hasNextPage={!!totalPages && page < totalPages}
        />
    );

    const newBookingView = (
        <CreateBookingDrawer
            parentID={showNewBooking?.clientId}
            parentName={showNewBooking?.clientName}
            onClose={backNewBookingHandler}
            onBooked={saveNewBookingHandler}
            showTabs={false}
        />
    );

    const bulkUploadView = (
        <Box className={classes.bulkUploadContainerWrapper}>
            <Box className={classes.bulkUploadContainer}>
                <ClientBulkUpload onClose={() => setShowBulkUpload(false)} />
            </Box>
        </Box>
    );

    const clientsNotFound = (
        <Box className={classes.clientsNotCreatedContainer}>
            <ClientsNotFound
                disableAddButton={false}
                onAddClient={props.onAddClient}
            />
        </Box>
    );

    const clientsDontExist = (
        <Box className={classes.clientsNotCreatedContainer}>
            <ClientsNotCreated
                disableAddButton={false}
                onAddClient={props.onAddClient}
            />
        </Box>
    );

    return (
        <Box className={classes.container}>
            {!loading && !clientsDone && clients.length === 0 ? (
                onBoarding
            ) : (
                <Box>
                    <Box>{header}</Box>
                    <Box>
                        {(loading || clients.length > 0) && table}
                        {!loading && clients.length === 0 && !isLastSearchAll && clientsNotFound}
                        {!loading && clients.length === 0 && isLastSearchAll && clientsDontExist}
                    </Box>
                </Box>
            )}
            {showNewBooking && newBookingView}
            {showBulkUpload && bulkUploadView}
        </Box>
    );
};

export default Clients;
