import React from 'react';
import { useCounterFieldStyles } from 'components/UI/V2/CounterField/CounterField.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleMinus, faCirclePlus } from '@fortawesome/pro-light-svg-icons';
import clsx from 'clsx';

export interface CounterFieldProps {
    value?: number;
    minLimit?: number;
    maxLimit?: number;
    readOnly?: boolean;
    onChange: (value: number) => void;
}

export const CounterField: React.FC<CounterFieldProps> = ({ readOnly = true, ...props }) => {
    const classes = useCounterFieldStyles(props);

    const changeHandler = (action: 'increment' | 'decrement') => {
        if (!props.value) {
            return;
        }

        if (action === 'increment') {
            incrementHandler(props.value);
        } else {
            decrementHandler(props.value);
        }
    };

    const incrementHandler = (value: number) => {
        if (props.maxLimit && value >= props.maxLimit) {
            return;
        }

        props.onChange(value + 1);
    };

    const decrementHandler = (value: number) => {
        if (props.minLimit && value <= props.minLimit) {
            return;
        }

        props.onChange(value - 1);
    };

    React.useEffect(() => {
        if (!props.value) {
            props.onChange(props.minLimit || 1);
        }
    }, []);

    return (
        <div className={classes.root}>
            <button
                type="button"
                id="counter-field-decrement-button"
                className={clsx(classes.button, readOnly && classes.hidden)}
                onClick={() => changeHandler('decrement')}
            >
                <FontAwesomeIcon icon={faCircleMinus} />
            </button>
            <label className={classes.label}>{props.value}</label>
            <button
                type="button"
                id="counter-field-increment-button"
                className={clsx(classes.button, readOnly && classes.hidden)}
                onClick={() => changeHandler('increment')}
            >
                <FontAwesomeIcon icon={faCirclePlus} />
            </button>
        </div>
    );
};
