import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useButtonStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'all': 'unset',
            'gap': 6,
            'fontSize': 14,
            'fontWeight': 600,
            'borderRadius': 30,
            'cursor': 'pointer',
            'whiteSpace': 'nowrap',
            'padding': '0px 16px',
            'fontfamily': 'Poppins',
            'alignItems': 'center',
            'display': 'inline-flex',
            'justifyContent': 'center',
            'position': 'relative',
            'overflow': 'hidden',
            'transition': 'background-color 0.2s ease-in-out',

            '&:disabled': {
                'opacity': 0.5,
                'cursor': 'not-allowed',

                '&[data-no-color-on-disabled="true"]': {
                    opacity: 1,
                    color: '#ffffff',
                    backgroundColor: '#D3D3D3'
                }
            },

            '&[data-only-icon="true"]': {
                padding: 0,
                height: 'auto',
                aspectRatio: '1/1',
                borderRadius: '50%'
            }
        },

        loading: {
            opacity: 0.7,
            cursor: 'not-allowed'
        },

        // Sizes
        small: {
            'height': 32,

            '&[data-only-icon="true"]': {
                width: 32
            }
        },
        medium: {
            'height': 38,
            'fontSize': 16,

            [theme.breakpoints.up('md')]: {
                height: 40
            },

            '&[data-only-icon="true"]': {
                width: 38,

                [theme.breakpoints.up('md')]: {
                    width: 40
                }
            }
        },
        large: {
            'height': 38,
            'fontSize': 16,

            [theme.breakpoints.up('md')]: {
                height: 52,
                fontSize: 18
            },

            '&[data-only-icon="true"]': {
                width: 38,

                [theme.breakpoints.up('md')]: {
                    width: 52
                }
            }
        },

        // Variants
        primary: {
            'color': '#ffffff',
            'backgroundColor': '#EAB464',

            '&:hover:not(:disabled)': {
                backgroundColor: '#C18733'
            }
        },
        green: {
            'color': '#ffffff',
            'backgroundColor': '#92B4A7',

            '&:hover:not(:disabled)': {
                backgroundColor: '#5E8677'
            }
        },
        black: {
            'color': '#ffffff',
            'backgroundColor': '#000000',

            '&:hover:not(:disabled)': {
                backgroundColor: '#565656'
            }
        },
        danger: {
            'color': '#ffffff',
            'backgroundColor': '#EF4F57',

            '&:hover:not(:disabled)': {
                backgroundColor: '#CC444B'
            }
        },

        // Spinner
        spinner: {
            inset: 0,
            zIndex: 10,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            backgroundColor: 'inherit'
        }
    })
);
