import React, { FunctionComponent, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { MarketplaceBasics, MarketplaceLogos } from '@spike/marketplace-model';
import { Button } from 'components/UI';
import { useNonInitialEffect } from '@versiondos/hooks';
import LogoInvoice from './UI/LogoInvoice';
import { useMasterData } from 'hooks';
import { MarketplaceStatus } from '@spike/marketplace-action';
import { BusinessSettingsTitle } from '../UI';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import UploadImage from '../OnlineBooking/UploadImage';

export interface Props {
    logos: MarketplaceLogos;
    basics: MarketplaceBasics;
    onSave?: (logos: MarketplaceLogos) => void;
    onChange?: (logos: MarketplaceLogos) => void;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',

            [theme.breakpoints.down('sm')]: {
                width: '100%',
                minHeight: '115%'
            }
        },
        titleContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px'
            }
        },
        businessSettingsTittle: {
            'display': 'flex',
            'alignItems': 'center',
            '& $iconBusinessTitle': {
                fontSize: '20px'
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '20px',
                marginBottom: '5px'
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        subtitleContainer: {
            maxWidth: '380px',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '22px',
                maxWidth: '100%'
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '23px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '31px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '47px'
            }
        },
        subtitle: {
            width: '100%',
            lineHeight: '160%',
            marginTop: '10px',
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%'
            }
        },
        content: {
            display: 'flex'
        },
        leftContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        rightContainer: {
            display: 'flex',
            flexDirection: 'column',
            width: '50%',
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        buttonContainer: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                marginTop: '13px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '21px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '28px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '43px'
            }
        },
        buttonContainerFull: {
            [theme.breakpoints.down('sm')]: {
                'width': '100%',
                'left': 0,
                '& button': {
                    'width': '100%',
                    'height': '50px',
                    'borderRadius': '31px',
                    '& .MuiButton-label': {
                        fontSize: '16px'
                    }
                }
            }
        },
        secondLine: {
            [theme.breakpoints.down('sm')]: {
                marginTop: '-3px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '-5px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '-7px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '-10px'
            }
        },
        right: {
            justifyContent: 'flex-end'
        },
        sectionContainer: {
            [theme.breakpoints.down('sm')]: {
                'marginBottom': '10px',
                '& p:not($titleSection)': {
                    fontSize: '14px',
                    lineHeight: '140%'
                }
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '32px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '43px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '65px'
            }
        },
        titleSection: {
            fontWeight: 500,
            [theme.breakpoints.down('sm')]: {
                marginBottom: '10px',
                lineHeight: '120%',
                fontSize: '16px',
                fontWeight: '500'
            },
            [theme.breakpoints.only('md')]: {
                marginBottom: '11px',
                lineHeight: '13px',
                fontSize: '16px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '14px',
                lineHeight: '18px',
                fontSize: '16px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '21px',
                lineHeight: '27px',
                fontSize: '16px'
            }
        },
        SettingsHorizontalLogo: {
            [theme.breakpoints.down('sm')]: {
                'width': '100% !important',
                'height': '105px !important',
                'marginTop': '10px',
                'marginBottom': '14px',
                '& div:first-child': {
                    backgroundSize: 'contain'
                },
                '& >div > p': {
                    display: 'none'
                }
            }
        },
        HorizontalLogoDropdown: {
            [theme.breakpoints.down('sm')]: {
                'top': '380px',
                'right': '15px',
                'left': 'auto',
                '& > .MuiBox-root': {
                    paddingLeft: '10px',
                    paddingRight: '10px'
                }
            }
        },
        squareImageContainer: {
            border: '2px solid black'
        },
        horizontalImageContainer: {
            'width': '240px !important',
            'height': '84px !important',
            'border': '2px solid black',
            '& div:first-child': {
                backgroundSize: 'contain'
            },

            [theme.breakpoints.down('sm')]: {
                width: '100% !important',
                height: '105px !important'
            }
        }
    })
);

export const Logos: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const status = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);
    const [saved, setSaved] = useState(false);
    const [loading, setLoading] = useState(false);

    const [logos, setLogos] = useState(props.logos);

    const masterData = useMasterData();

    useNonInitialEffect(() => {
        if (status === MarketplaceStatus.SaveSuccess) {
            setSaved(true);
            setLoading(false);
        }
    }, [status]);

    const handleChangeImageSquare = (imageFile: File | undefined, image: string | undefined) => {
        setLogos(prev => ({
            ...prev,
            promoImageSecondaryUrl: image,
            promoImageSecondaryFileToUpload: imageFile,
            promoImageSecondaryRemove: imageFile ? false : true
        }));
        setSaved(isEqual(props.logos, imageFile));
    };

    const handleChangeImageHorizontal = (imageFile: File | undefined, image: string | undefined) => {
        setLogos(prev => ({
            ...prev,
            imageUrl: image,
            imageFileToUpload: imageFile,
            imageRemove: imageFile ? false : true
        }));
        setSaved(isEqual(props.logos, imageFile));
    };

    const saveHandler = () => {
        props.onSave && props.onSave({ ...logos });
        props.onSave && setLoading(true);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.titleContainer}>
                <BusinessSettingsTitle className={classes.businessSettingsTittle}>
                    {' '}
                    {props.isMobile && (
                        <FontAwesomeIcon
                            className={classes.iconBusinessTitle}
                            onClick={props.openSidebar}
                            icon={faArrowLeft}
                            size="2x"
                        />
                    )}{' '}
                    Logo
                </BusinessSettingsTitle>
            </Box>
            <Box className={classes.content}>
                <Box className={classes.leftContainer}>
                    <Box className={classes.subtitleContainer}>
                        <Typography className={classes.subtitle}>
                            Customize your invoice & report by uploading your logo. Cuddles logo will remain the default
                            until changes are applied.
                        </Typography>
                        {/*<Typography className={clsx(classes.subtitle, classes.secondLine)}>Cuddles logo will remain the default until changes are applied.</Typography>*/}
                    </Box>
                    <Box className={classes.sectionContainer}>
                        <Typography className={classes.titleSection}>Square Logo</Typography>
                        <UploadImage
                            imageUrl={logos.promoImageSecondaryUrl}
                            onChangeImage={handleChangeImageSquare}
                            maxSize={masterData.imagesMaxSizes.squareLogo.maxSizeKb}
                            maxWidth={masterData.imagesMaxSizes.squareLogo.maxWidthPx}
                            maxHeight={masterData.imagesMaxSizes.squareLogo.maxHeightPx}
                            imageTypes={masterData.imagesMaxSizes.squareLogo.imageTypes}
                            containerClassName={classes.squareImageContainer}
                            type="square"
                        />
                    </Box>
                    <Box className={classes.sectionContainer}>
                        <Typography className={classes.titleSection}>Horizontal Logo</Typography>
                        <UploadImage
                            imageUrl={logos.imageUrl}
                            onChangeImage={handleChangeImageHorizontal}
                            maxSize={masterData.imagesMaxSizes.horizontalLogo.maxSizeKb}
                            maxWidth={masterData.imagesMaxSizes.horizontalLogo.maxWidthPx}
                            maxHeight={masterData.imagesMaxSizes.horizontalLogo.maxHeightPx}
                            imageTypes={masterData.imagesMaxSizes.horizontalLogo.imageTypes}
                            containerClassName={classes.horizontalImageContainer}
                            type="square"
                        />
                    </Box>
                </Box>
                <Box className={classes.rightContainer}>
                    <LogoInvoice basics={props.basics} logo={logos.promoImageSecondaryUrl} />
                </Box>
            </Box>
            <Box className={clsx(classes.buttonContainer, classes.buttonContainerFull)}>
                <Button label={saved ? 'Saved!' : 'Save'} onClick={saveHandler} loading={loading} />
            </Box>
        </Box>
    );
};

export default Logos;
