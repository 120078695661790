import { Box, Drawer, Theme, Typography, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import FooterComponent from 'components/CreateBooking/FooterComponent';
import {
    useApiClientWrapper,
    useAuth,
    useHasPremiumAccess,
    useHubspotTrackingCode,
    useMarketplace,
    useMasterData
} from 'hooks';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';
import { FunctionComponent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import store, { RootState } from 'store';
import BookingDetailHeader from './BookingDetailHeader';
import {
    AppointmentsState,
    AppointmentsStatus,
    BookingsState,
    BookingsStatus,
    cancelAppointmentThunk,
    cancelBookingThunk,
    cancelRecurrentAppointmentsThunk,
    changeAppointmentNotesThunk,
    changeStatusAppointmentThunk,
    completeAppointmentThunk,
    confirmBookingThunk,
    countRecurrentAppointmentsThunk,
    declineAppointmentThunk,
    deleteAppointmentThunk,
    getBookingThunk,
    refetchAppointmentsThunk
} from '@spike/bookings-action';

import useNonInitialEffect from '@versiondos/hooks';
import Appointment, { AppointmentCountRecurrent, AppointmentService, status } from '@spike/appointment-model';
import Booking, { BookingWrapper } from '@spike/booking-model';
import Client from '@spike/client-model';
import { NewAppointment } from 'model/NewBooking';
import Pet from '@spike/pet-model';
import Service from 'model/Service';
import { Staff } from 'model/Staff';
import { PetsState, PetsStatus, getPetThunk } from '@spike/pets-action';
import { fetchThunk as fetchNotificationThunk } from 'actions/userNotifications/userNotificationActions';
import { ClientsState, ClientsStatus, getClientThunk } from '@spike/clients-action';
import ClientInfo from './ClientInfo';
import PetInfo from './PetInfo';
import Notes from './Notes';
import DateInfo from './DateInfo';
import ServiceInfo from './ServiceInfo';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { saveCreateAppointmentThunk } from 'actions/newBookings/NewBookingsActions';
import { Moment } from 'moment-timezone';
import { v4 as uuid } from 'uuid';
import { Status } from 'model';
import { Button, ConfirmBooking, ConfirmDialog, OverDetail, Spinner } from 'components/UI';
import Checkout from 'components/Checkout';
import { CompleteReport, EditReport, ViewReport } from 'components/Reports';
import PopUpCancelBooking from 'components/UI/PopUpCancelBooking';
import DisplayInvoice from 'components/Invoice/DisplayInvoice';
import CreateBookingDrawer from 'components/UI/CreateBookingDrawer';
import { round, uniq } from 'lodash';
import ServiceSelector from 'components/CreateBooking/ServiceSelector';
import StaffSelector from 'components/CreateBooking/StaffSelector';
import { NewBookingAlert, PetService } from '@spike/new-booking-model';
import {
    NewBookingsState,
    NewBookingsStatus,
    changeBookingStatusThunk,
    fetchAlertsThunk
} from '@spike/new-bookings-action';
import {
    NewBookingsState as NewBookingsOldState,
    NewBookingsStatus as NewBookingsOldStatus
} from 'reducers/newBookings/NewBookingsState';
import { isAddingServicesEnabled } from './utils/AppointmentUtils';
import PetCard from 'components/PetCard';
import CompleteAppointmentDialog from 'components/UI/CompleteAppointmentDialog';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import BookingRequestDetail from 'components/BookingDetail2/BookingRequestDetail';
import Info from 'components/UI/V2/Info';

interface DetailBookingProps {
    bookingId: number;
    appointmentId: number;
    clientId?: number;
    className?: string;
    fromClient?: boolean;
    onBack: () => void;
    onRebook?: (selectedBooking: Booking) => void;
    onReschedule: (booking: BookingWrapper, appointmentId?: number) => void;
    onShowClient: (clientId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'display': 'flex',
            'height': '100%',
            'flexDirection': 'column',
            '&.MuiDrawer-root.MuiDrawer-modal': {
                zIndex: '10 !important'
            },
            '& .MuiDrawer-paperAnchorRight': {
                width: '100%',
                [theme.breakpoints.up('md')]: {
                    width: '590px !important'
                }
            }
        },
        headerContainer: {
            display: 'flex',
            backgroundColor: '#FFFFFF',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '10%',
            padding: '8px 27px 11px 27px',
            marginLeft: 3
        },
        bookingDetailContainer: {
            backgroundColor: '#FFFFFF'
        },
        footerContainer: {
            height: 'auto',
            borderTop: '1px solid #D4D4D4'
        },
        section: {
            paddingLeft: 26,
            paddingRight: 26,
            paddingBottom: 26,
            [theme.breakpoints.up('md')]: {
                paddingLeft: 30,
                paddingRight: 30,
                paddingBottom: 30
            }
        },
        sectionContainer: {
            margin: '17px 27px 11px 27px',
            [theme.breakpoints.up('md')]: {
                margin: '20px 30px 13px 30px'
            }
        },
        sectionGrey: {
            backgroundColor: '#FAFAFA'
        },
        iconContainer: {
            display: 'flex',
            justifyContent: 'center',
            width: '100%',
            [theme.breakpoints.down(960)]: {
                display: 'none'
            }
        },
        iconTimes: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#000000',
            borderRadius: '50%',
            top: 24,
            right: 620,
            position: 'fixed',
            cursor: 'pointer',
            height: 46,
            width: 46
        },
        icon: {
            color: '#FFFFFF',
            height: 20,
            width: 20
        },
        boxActionsBorder: {
            borderLeft: '1px solid #D3D3D3',
            [theme.breakpoints.up('md')]: {
                paddingLeft: 15
            }
        },
        boxSpinner: {
            display: 'flex',
            height: '100%'
        },
        petCardContainer: {
            zindex: 1400
        },
        infoContainer: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center'
        }
    })
);

interface PetServiceWrapper extends PetService {
    duration?: Duration;
}

interface Report {
    appointmentId: number;
    businessAreaId: string;
    onlyAddReport?: boolean;
    sendSms?: boolean;
    clientId?: number;
}

interface NewAppointmentCreate {
    appointmentId?: number;
    statusId: number;
}

export const DetailBooking: FunctionComponent<DetailBookingProps> = props => {
    const classes = useStyles();

    const masterData = useMasterData();
    const marketplace = useMarketplace();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();
    const auth = useAuth();
    const { setTrackEvent } = useHubspotTrackingCode();
    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const appointmentsState = useSelector<RootState, AppointmentsState>(state => state.appointments);

    const bookingState = useSelector<RootState, BookingsState>(state => state.bookings);
    const clientState = useSelector<RootState, ClientsState | undefined>(state => state.clients);
    const petState = useSelector<RootState, PetsState | undefined>(state => state.pets);

    const newBookingState = useSelector<RootState, NewBookingsState>(state => state.newBookings);

    const newBookingOldState = useSelector<RootState, NewBookingsOldState>(state => state.newBookingsOld);

    const alerts = useSelector<RootState, Array<NewBookingAlert> | undefined>(state => state.newBookings.alerts);

    const services = useSelector<RootState, Array<Service>>(state => state.services.services).filter(
        service => service.status.id === Status.ACTIVE
    );

    const petServices = useSelector<RootState, Array<PetService>>(state => state.newBookings.petServices);

    const emailConfigured =
        clientState?.client && clientState?.client.email && clientState?.client.email.length > 0 ? true : false;

    const recurrentInfo = useSelector<RootState, undefined | AppointmentCountRecurrent>(
        state => state.appointments.recurrent
    );

    const [appointment, setAppointment] = useState<Appointment>();
    const [appointments, setAppointments] = useState<Array<Appointment>>([]);
    const [booking, setBooking] = useState<Booking>();
    const [pet, setPet] = useState<Pet>();
    const [petSelectedId, setPetSelectedId] = useState<number | undefined>();
    const [client, setClient] = useState<Client>();
    const [loadingService, setLoadingService] = useState(false);
    const [loadingConfirm, setLoadingConfirm] = useState<boolean>(false);
    const [loadingAction, setLoadingAction] = useState(false);
    const [rescheduleBooking, setRescheduleBooking] = useState<BookingWrapper | undefined>(undefined);
    const [showAddReport, setShowAddReport] = useState<Report | undefined>(undefined);
    const [decline, setDecline] = useState<Report | undefined>(undefined);
    const [invoiceId, setInvoiceId] = useState<number | undefined>();
    const [appointmentReport, setAppointmentReport] = useState<Appointment | undefined>();

    const [actionNoShow, setActionNoShow] = useState(false);

    const [bookingDelete, setBookingDelete] = useState<Booking>();
    const [appointmentDelete, setAppointmentDelete] = useState<Appointment>();

    // New Appointment
    const [newAppointment, setNewAppointment] = useState<NewAppointmentCreate | undefined>();

    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showCancelBooking, setShowCancelBooking] = useState(false);
    const [showDecline, setShowDecline] = useState(false);
    const [showInvoice, setShowInvoice] = useState<boolean>(false);
    const [showNewBooking, setShowNewBooking] = useState<boolean>(false);
    const [showReport, setShowReport] = useState<boolean>(false);
    const [showModalCheckout, setShowModalCheckout] = useState(false);
    const [reload, setReload] = useState(false);
    const [cancelLoading, setCancelLoading] = useState(false);
    const [showAddService, setShowAddService] = useState<boolean>(false);
    const [showShowStaff, setShowStaff] = useState<boolean>(true);
    const [service, setService] = useState<PetServiceWrapper | undefined>();
    const [staffSelected, setStaffSelected] = useState<Staff | undefined>();
    const [uniquePet, setUniquePet] = useState<boolean>(true);

    const [editReport, setEditReport] = useState<Report | undefined>(undefined);
    const [showEditReport, setShowEditReport] = useState(false);

    const [checkoutAppointmentIds, setCheckoutAppointmentIds] = useState<Array<number>>([]);

    const [showPetCard, setShowPetCard] = useState(false);
    const [showModalCompleteAppointment, setShowModalCompleteAppointment] = useState(false);
    const [completeReportData, setCompleteReportData] = useState<Appointment | undefined>(undefined);

    const statusWithCheckoutButton = [
        status.BOOKED,
        status.CHECKED_IN,
        status.CONFIRMED,
        status.IN_PROGRESS,
        status.READY_FOR_CHECK_OUT,
        status.PAYMENT,
        status.PENDING,
        status.WAITING_PAYMENT_CONFIRMATION
    ];

    const loadBooking = () => {
        dispatch(getBookingThunk(apiClientWrapper, props.bookingId));
    };

    const handlePetSelect = (petId: number) => {
        setPet(undefined);
        setPetSelectedId(petId);
        dispatch(getPetThunk(apiClientWrapper, petId));
        const appointmentFounded = appointments.find(app => app.pet.id === petId);
        setAppointment(appointmentFounded);
    };

    const handlePetRefresh = () => {
        dispatch(getPetThunk(apiClientWrapper, appointment?.pet?.id!));
        dispatch(refetchAppointmentsThunk(apiClientWrapper));
        setShowPetCard(false);
    };

    const getServicesUsed = () => {
        const servicesUsed: Array<number> = [];
        booking?.appointments.forEach(app => {
            if (app.pet.id === appointment?.pet.id) {
                servicesUsed.push(app.services[0].id);
            }
        });
        return servicesUsed;
    };

    const refreshAppointments = (appointmentsRefresh: Array<Appointment>) => {
        const appointmentsOrigin: Array<Appointment> = appointments;
        const appointmentsTemp: Array<Appointment> = [];

        appointmentsOrigin.map(a => {
            let app = appointmentsRefresh.find(as => as.uuid === a.uuid);

            if (app) {
                appointmentsTemp.push(app);
            } else {
                app = appointmentsRefresh.find(as => as.id === a.id);
                app ? appointmentsTemp.push(app) : appointmentsTemp.push(a);
            }

            return '';
        });

        setAppointments(appointmentsTemp);
    };

    const searchStatusToNewAppointment = (appointment: Appointment) => {
        setNewAppointment({ statusId: appointment.status.id });
        return {
            ...masterData.workflow.find(step => step.status.id === status.BOOKED)!.status
        };
    };

    const backReportHandler = () => {
        setShowEditReport(false);
        setShowReport(false);
    };

    const createAppointment = () => {
        const serviceFounded = services.find(s => s.id === service?.service.id);

        const serviceFormatted: AppointmentService = {
            id: service?.service.id!,
            name: service?.service?.name!,
            staff: undefined,
            businessArea: serviceFounded?.businessArea!,
            hasReport: false,
            price: service?.price!
        };

        return {
            uuid: uuid(),
            bookingId: booking!.id!,
            pet: appointment!.pet,
            customer: appointment!.customer,
            services: [serviceFormatted],
            status: searchStatusToNewAppointment(appointment!),
            duration: appointment!.duration,
            notes: '',
            total: 0,
            destroyable: true,
            reports: {},
            cancelationReasonId: null
        };
    };

    const getNewAppointment = (app: Appointment) => {
        const newAppointment: NewAppointment = {
            booking_id: props.bookingId,
            address_id: store.getState().marketplace.marketplace.basics.address.id as number,
            booked_at: getBookedAt(booking!),
            customer_id: app.customer.id,
            pet_id: app.pet.id,
            service_id: app.services[0].id,
            staff_id: staffSelected?.id!,
            status: app.status.id,
            uuid: app.uuid,
            notes: app.notes,
            created_by: store.getState().login.auth.user!.staffId
        };
        return newAppointment;
    };

    const noShowHandler = (appointmentId: number) => {
        setActionNoShow(true);
        setLoadingAction(true);
        changeStatusHandler(appointmentId, status.NO_SHOW);
    };

    const changeStatusHandler = async (
        appointmentId: number,
        statusId: number,
        check?: boolean,
        fetchNotification?: boolean,
        sendNotification?: boolean
    ) => {
        await dispatch(changeStatusAppointmentThunk(apiClientWrapper, appointmentId, statusId, check));

        if (fetchNotification) {
            dispatch(fetchNotificationThunk());
        }
    };

    const changeBookingStatusHandler = async (bookingId: number, statusId: number, sendNotification?: boolean) => {
        setLoadingAction(true);

        await dispatch(changeBookingStatusThunk(apiClientWrapper, bookingId, statusId, false, sendNotification));

        dispatch(fetchNotificationThunk());
    };

    /* confirm booking */
    const confirmHandler = () => {
        setLoadingConfirm(true);
        dispatch(confirmBookingThunk(apiClientWrapper, bookingState.booking!.id!));
    };

    const getTotal = (appointments: Array<Appointment>) => {
        return `${round(
            appointments.reduce((total, appointment) => total + appointment.total, 0),
            2
        ).toFixed(2)}`;
    };

    const onCancelBookingFromConfirmHandler = () => {
        setShowConfirmation(false);
        dispatch(cancelBookingThunk(apiClientWrapper, bookingState.booking!.id!));
    };

    const confirmBookingHandler = () => {
        setShowConfirmation(false);
        dispatch(confirmBookingThunk(apiClientWrapper, bookingState.booking!.id!));
    };
    /* END::confirm booking */

    // BEGIN::reschedule
    const rescheduleBookingHandler = () => {
        props.onReschedule(rescheduleBooking!, appointment?.id);
    };

    const rescheduleAppointmentHandler = () => {
        props.onReschedule(rescheduleBooking!, appointment?.id);
    };

    // END::reschedule

    const backNewBookingHandler = () => {
        setShowNewBooking(false);
    };

    const saveNewBookingHandler = () => {
        props.onBack();
    };

    const declineHandler = (app: Appointment) => {
        const businessAreaId = app!.services[0].businessArea.id;
        if (app?.services[0].hasReport) {
            const appointmentId = app.id!;
            setDecline({ appointmentId, businessAreaId });
            setShowDecline(true);
        } else {
            dispatch(declineAppointmentThunk(apiClientWrapper, app?.id!));
        }
    };

    const checkOutHandler = () => {
        const invalidCheckoutStatuIds = [status.BOOKED, status.CONFIRMED, status.CHECKED_IN, status.IN_PROGRESS];
        const validCheckoutStatuIds = [status.READY_FOR_CHECK_OUT, status.PAYMENT];

        const existNotReadyAppointment =
            booking?.appointments.some(appointment => invalidCheckoutStatuIds.includes(appointment.status.id)) || false;

        if (existNotReadyAppointment) {
            setShowModalCheckout(true);
        } else {
            setCheckoutAppointmentIds(
                booking?.appointments
                    .filter(appointment => validCheckoutStatuIds.includes(appointment.status.id))
                    .map(appointment => appointment.id!) || []
            );
        }
    };

    const closeReportHandler = () => {
        setShowReport(false);
        setShowEditReport(false);
        setShowAddReport(undefined);
    };

    const saveReportHandler = (bookingId: number) => {
        dispatch(getBookingThunk(apiClientWrapper, bookingId));
    };

    const changeStatusSelectedHandler = (status: number) => {
        changeStatusHandler(appointment?.id!, status);
    };

    const saveReportDeclineHandler = (bookingId: number) => {
        dispatch(getBookingThunk(apiClientWrapper, bookingId));
        setShowDecline(false);
    };

    const showReportHandler = (app?: Appointment) => {
        setShowReport(true);
        setAppointmentReport(app ?? appointment);
    };

    const editReportGroomingHandler = (appointmentId: number, businessAreaId: string, clientId?: number) => {
        setShowReport(false);
        setAppointmentReport(undefined);
        setEditReport({ appointmentId, businessAreaId, clientId });
        setShowEditReport(true);
    };

    const editReportDentalHandler = (appointmentId: number, businessAreaId: string) => {
        setShowReport(false);
        setAppointmentReport(undefined);
        setEditReport({ appointmentId, businessAreaId });
        setShowEditReport(true);
    };
    // END::report

    const undoHandler = (appointment: Appointment, statusId: number) => {
        setLoadingAction(true);
        appointment.status.id !== status.NO_SHOW
            ? changeStatusHandler(appointment.id!, statusId)
            : changeStatusHandler(appointment.id!, status.BOOKED);
    };

    const viewReportHandler = () => {
        setEditReport(undefined);
        setShowEditReport(false);
        setShowReport(true);
    };

    const showInvoiceHandler = (appointmentId?: number) => {
        const invoiceId = appointments!.find(ap => ap.id === appointmentId || ap.id === appointment?.id)!.invoiceId;
        if (invoiceId) {
            setShowInvoice(true);
            setInvoiceId(invoiceId);
        }
    };

    const backHandler = () => {
        setService(undefined);
        setStaffSelected(undefined);
        setShowAddService(false);
    };

    const changeNoteHandler = (noteChanged: string) => {
        dispatch(changeAppointmentNotesThunk(apiClientWrapper, appointment?.id!, noteChanged));
    };

    const removeServiceHandler = (appUuid: string, appId?: number) => {
        if (appId) dispatch(deleteAppointmentThunk(apiClientWrapper, appId!));
        const appointmentsAux = appointments.filter(ap => ap.uuid !== appUuid);
        setAppointments(appointmentsAux);
    };

    const getBookedAt = (booking: Booking) => {
        return booking.appointments.reduce<Moment | null>(
            (result, appointment) =>
                result === null || appointment.duration.to > result ? appointment.duration.to : result,
            null
        )!;
    };

    const saveServiceHandler = () => {
        dispatch(saveCreateAppointmentThunk(getNewAppointment(createAppointment())));
        setReload(true);
    };

    const addReportHandler = (appointmentId: number, businessAreaId: string, clientId: number) => {
        if (!props.fromClient) {
            dispatch(getClientThunk(apiClientWrapper, clientId));
        }

        setShowAddReport({
            appointmentId,
            businessAreaId,
            onlyAddReport: true
        });
    };

    const onBackAddReportHandler = () => {
        setShowAddReport(undefined);
    };

    /* Begin:: Cancel */

    const onCancelBookingHandler = () => {
        setBookingDelete(booking);
        setAppointmentDelete(undefined);

        setShowCancelBooking(true);
    };

    const onCancelAppointmentHandler = (appointmentId?: number) => {
        if (appointmentId) {
            const appointmentWrapper = booking?.appointments.find(w => w.id === appointmentId)!;

            setAppointmentDelete(appointmentWrapper);
            setBookingDelete(undefined);

            setShowCancelBooking(true);
        }
    };

    /* BEGIN:: report*/
    const completeHandler = (appointmentToComplete: Appointment, sendSms: boolean) => {
        setShowModalCompleteAppointment(false);

        dispatch(completeAppointmentThunk(apiClientWrapper, appointmentToComplete?.id!, sendSms));
        setTrackEvent(`${process.env.REACT_APP_HUBSPOT_EVENT_APPOINTMENT_COMPLETED}`);
    };

    const completeWithModalHandler = (appointmentToComplete: Appointment) => {
        setCompleteReportData(appointmentToComplete);
        setShowModalCompleteAppointment(true);
    };

    const closeCancelHandler = () => {
        setShowCancelBooking(false);
    };

    const confirmCancelHandler = (
        bookingId?: number,
        appointmentId?: number,
        check?: boolean,
        cancelationReasonId?: number
    ) => {
        const bookId = bookingDelete ? bookingDelete.id : undefined;
        const apptId = appointmentDelete ? appointmentDelete.id : undefined;

        if (bookId) {
            dispatch(cancelBookingThunk(apiClientWrapper, bookId, check, cancelationReasonId));
        }

        if (!bookId && apptId) {
            dispatch(cancelAppointmentThunk(apiClientWrapper, apptId, check, cancelationReasonId));
        }
    };

    const confirmCancelRecurrentHandler = (appointmentId: number, check?: boolean) => {
        setCancelLoading(true);
        dispatch(cancelRecurrentAppointmentsThunk(apiClientWrapper, appointmentId, status.CANCELLED, check));
    };
    /* End::Delete & Cancel */

    const isCheckoutDisabled = () => {
        let disabled = false;
        booking?.appointments.forEach(ap => {
            if (ap.status.id < 5) {
                disabled = true;
            }
        });
        return disabled;
    };

    const isCheckoutNotVisible = () => {
        let notVisible = true;
        booking?.appointments.forEach(ap => {
            if (statusWithCheckoutButton.includes(ap.status.id)) {
                notVisible = false;
            }
        });
        return notVisible;
    };

    const getAppointmentToConfirm = () => {
        const appointmentsAdvanced = appointments.find(ap => ap.status.id > 4);
        if (!appointmentsAdvanced) {
            return appointments.find(ap => ap.status.id === 1);
        } else {
            return undefined;
        }
    };

    const getServicesUnused = () => {
        const servicesUsed = getServicesUsed();
        if (servicesUsed) {
            const servicesToSelect: Array<PetServiceWrapper> = [];
            petServices.forEach(serv => {
                const serviceFounded = servicesUsed?.find(s => s === serv?.service.id);
                if (!serviceFounded) {
                    servicesToSelect.push(serv);
                }
            });
            return servicesToSelect;
        }
        return services;
    };

    useNonInitialEffect(() => {
        switch (bookingState.status) {
            case BookingsStatus.GetSuccess:
                const appointmentLoad =
                    bookingState.booking!.appointments.find(app => app.id === props.appointmentId) ||
                    bookingState.booking!.appointments[0];

                if (appointmentLoad.recurringUuid) {
                    dispatch(countRecurrentAppointmentsThunk(apiClientWrapper, appointmentLoad.id!));
                }

                if (!appointment || reload === true) {
                    setAppointment(appointmentLoad);
                } else {
                    const a = bookingState.booking!.appointments.find(app => app.id === appointment.id);

                    const app = booking?.appointments[0];
                    setAppointment(app);

                    a ? setAppointment(a) : setAppointment(app);
                }

                appointments.length > 0 && reload === false
                    ? refreshAppointments(bookingState!.booking!.appointments!)
                    : setAppointments(bookingState!.booking!.appointments!);
                setRescheduleBooking({
                    booking: bookingState.booking!,
                    key: bookingState.booking!.uuid,
                    sort: {
                        id: bookingState.booking!.id!,
                        clientName: `${appointmentLoad!.customer.firstName} ${appointmentLoad!.customer.lastName}`,
                        petName: appointmentLoad!.pet.name,
                        petBreed: appointmentLoad!.pet.breed.name,
                        petType: appointmentLoad!.pet.type.singular,
                        service: appointmentLoad!.services[0].name,
                        staff: `${appointmentLoad!.services[0].staff?.firstName} ${
                            appointmentLoad!.services[0].staff?.lastName
                        }`,
                        dateTime: bookingState.booking!.bookedAt,
                        total: appointmentLoad!.total,
                        status: appointmentLoad!.status.id
                    }
                });

                if (!pet || reload === true) dispatch(getPetThunk(apiClientWrapper, appointmentLoad!.pet.id));

                if (!client || reload === true) {
                    if (clientState?.client?.id === bookingState.booking!.customer.id) {
                        setClient(clientState?.client);
                    } else {
                        dispatch(getClientThunk(apiClientWrapper, bookingState!.booking!.customer.id));
                    }
                }

                setLoadingAction(false);
                setLoadingService(false);
                setLoadingConfirm(false);
                setReload(false);
                break;
            case BookingsStatus.Canceling:
                setCancelLoading(true);
                loadBooking();
                break;
            case BookingsStatus.CancelSuccess:
                setCancelLoading(false);
                setShowCancelBooking(false);
                props.onBack();
                break;
            case BookingsStatus.ConfirmSuccess:
                dispatch(getBookingThunk(apiClientWrapper, props.bookingId));
                break;
            case BookingsStatus.DeleteSuccess:
                props.onBack();
                break;
            case BookingsStatus.Error:
                setReload(false);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingState]);

    useEffect(() => {
        loadBooking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useNonInitialEffect(() => {
        setReload(true);
        loadBooking();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.bookingId]);

    useNonInitialEffect(() => {
        if (clientState?.status === ClientsStatus.GetSuccess) {
            setClient(clientState.client);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clientState]);

    useNonInitialEffect(() => {
        if (petState?.status === PetsStatus.GetSuccess) {
            setPet(petState.pet);
        } else if (petState?.status === PetsStatus.SavePetSuccess) {
            dispatch(getPetThunk(apiClientWrapper, appointment!.pet!.id));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [petState]);

    useNonInitialEffect(() => {
        switch (newBookingState.status) {
            case NewBookingsStatus.SaveForDepositSuccess:
            case NewBookingsStatus.SaveSuccess:
            case NewBookingsStatus.RecurrentRescheduleSuccess:
            case NewBookingsStatus.SingleRescheduleSuccess:
                setReload(true);
                break;
            case NewBookingsStatus.ChangeStatusSuccess:
                loadBooking();
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBookingState]);

    useNonInitialEffect(() => {
        switch (newBookingOldState.status) {
            case NewBookingsOldStatus.SavingCreateAppointment:
                setLoadingService(true);
                break;
            case NewBookingsOldStatus.SavingCreateAppointmentSuccess:
                setShowAddService(false);
                setLoadingService(false);
                const statusId = newAppointment?.statusId;

                setNewAppointment({
                    appointmentId: newBookingOldState.newAppointment!.id!,
                    statusId: Number(statusId)
                });

                if (status.BOOKED !== appointments[0].status.id) {
                    changeStatusHandler(newBookingOldState.newAppointment!.id!, status.CONFIRMED);
                }

                dispatch(getBookingThunk(apiClientWrapper, props.bookingId));
                setService(undefined);
                setStaffSelected(undefined);
                break;
            default:
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newBookingOldState]);

    useNonInitialEffect(() => {
        switch (appointmentsState.status) {
            case AppointmentsStatus.ChangeStatusSuccess:
                switch (newAppointment?.statusId) {
                    case status.BOOKED:
                        setLoadingAction(false);
                        dispatch(getBookingThunk(apiClientWrapper, props.bookingId));
                        setNewAppointment(undefined);
                        break;
                    default:
                        if (actionNoShow) {
                            loadBooking();
                            setActionNoShow(false);
                        } else {
                            dispatch(getBookingThunk(apiClientWrapper, props.bookingId));
                        }
                        setLoadingAction(false);
                        break;
                }
                setCancelLoading(false);
                setShowCancelBooking(false);
                setLoadingAction(false);
                break;
            case AppointmentsStatus.DeclineSuccess:
                loadBooking();
                break;
            case AppointmentsStatus.ChangeServiceSuccess:
            case AppointmentsStatus.CompleteSuccess:
            case AppointmentsStatus.AssignStaffSuccess:
            case AppointmentsStatus.ChangeNotesSuccess:
            case AppointmentsStatus.DeleteSuccess:
                dispatch(getBookingThunk(apiClientWrapper, props.bookingId));
                setLoadingService(false);
                break;
            case AppointmentsStatus.Deleting:
                setLoadingService(true);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appointmentsState]);

    useEffect(() => {
        if (appointment) {
            dispatch(
                fetchAlertsThunk(
                    apiClientWrapper,
                    appointment?.customer.id || 0,
                    appointment?.pet.id || 0,
                    appointment!.duration!.from.tz(marketplace.schedule.timeZone),
                    !!appointment.recurringUuid,
                    service?.service.id,
                    staffSelected?.id,
                    appointment.id
                )
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [service, staffSelected]);

    useEffect(() => {
        const isUniquePet =
            uniq(bookingState.booking?.appointments.map(appointment => appointment.pet.id)).length === 1;
        setUniquePet(isUniquePet);
    }, [booking]);

    useEffect(() => {
        if (bookingState.booking) {
            setBooking(bookingState.booking);
        }
    }, [bookingState.booking]);

    useEffect(() => {
        if (getServicesUnused()?.length === 0) {
            setShowStaff(false);
        } else {
            setShowStaff(true);
        }
    }, [petServices]);

    const loadingView = (
        <Box className={classes.boxSpinner}>
            <Spinner />
        </Box>
    );

    const reportView = (
        <OverDetail
            fullWindow
            fullHeight
        >
            <ViewReport
                appointmentId={appointmentReport ? appointmentReport!.id! : 0}
                businessAreaId={appointmentReport ? appointmentReport!.services[0].businessArea.id : ''}
                //
                onBack={closeReportHandler}
                onClose={closeReportHandler}
                onEditGrooming={() =>
                    editReportGroomingHandler(
                        appointmentReport!.id!,
                        appointmentReport!.services[0].businessArea.id,
                        appointmentReport?.customer.id!
                    )
                }
                onEditDental={() =>
                    editReportDentalHandler(appointmentReport!.id!, appointmentReport!.services[0].businessArea.id)
                }
            />
        </OverDetail>
    );

    const confirmationView = (
        <ConfirmBooking
            booking={booking!}
            open={showConfirmation}
            pet={pet}
            //
            onConfirm={confirmBookingHandler}
            onCancel={onCancelBookingFromConfirmHandler}
            onReschedule={rescheduleAppointmentHandler}
            onCloseDialog={() => setShowConfirmation(false)}
        />
    );

    const newBookingView = (
        <CreateBookingDrawer
            parentID={booking?.customer.id}
            parentName={booking?.customer.firstName + ' ' + booking?.customer.lastName}
            petID={appointment?.pet.id}
            onClose={backNewBookingHandler}
            onBooked={saveNewBookingHandler}
            showTabs={false}
        />
    );

    const invoiceView = (
        <OverDetail
            fullWindow
            fullHeight
        >
            <DisplayInvoice
                invoiceId={invoiceId!}
                onBack={() => setShowInvoice(false)}
            />
        </OverDetail>
    );

    const cancelBookingView = (
        <PopUpCancelBooking
            loading={cancelLoading}
            booking={bookingDelete}
            appointment={appointmentDelete}
            //
            onClose={closeCancelHandler}
            onConfirmCancel={confirmCancelHandler}
            onConfirmCancelRecurrent={confirmCancelRecurrentHandler}
        />
    );

    const declinedView = (
        <OverDetail
            fullWindow
            fullHeight
        >
            <CompleteReport
                appointmentId={decline?.appointmentId!}
                businessAreaId={decline?.businessAreaId!}
                onBack={() => setShowDecline(false)}
                onClose={() => setShowDecline(false)}
                onSave={() => saveReportDeclineHandler(props.bookingId)}
                declined={true}
            />
        </OverDetail>
    );

    const checkoutView = (
        <OverDetail
            fullWindow
            fullHeight
        >
            <Checkout
                appointmentIds={checkoutAppointmentIds}
                bookingId={props.bookingId}
                onBack={() => setCheckoutAppointmentIds([])}
                onComplete={props.onBack}
                onShowClient={props.onShowClient}
            />
        </OverDetail>
    );

    const addReportView = (
        <OverDetail
            fullWindow
            fullHeight
        >
            <CompleteReport
                appointmentId={showAddReport?.appointmentId!}
                businessAreaId={showAddReport?.businessAreaId!}
                onBack={onBackAddReportHandler}
                onClose={closeReportHandler}
                declined={false}
                onlyAddReport={showAddReport?.onlyAddReport!}
                emailConfigured={emailConfigured}
            />
        </OverDetail>
    );

    const bookingNotReadyForCheckoutModal = (
        <ConfirmDialog
            open={showModalCheckout}
            question={
                <>
                    This appointment is unified with another, make sure that they are all in completed status, in order
                    to continue to checkout.
                </>
            }
            actions={
                <Box
                    sx={{
                        width: '100%',
                        textAlign: 'center'
                    }}
                >
                    <Button
                        variant="primary"
                        label="Understood"
                        onClick={() => setShowModalCheckout(false)}
                    />
                </Box>
            }
            onCancel={() => setShowModalCheckout(false)}
        />
    );

    const editReportView = (
        <OverDetail
            fullWindow
            fullHeight
        >
            <EditReport
                appointmentId={editReport?.appointmentId!}
                businessAreaId={editReport?.businessAreaId!}
                savePDF={true}
                onBack={backReportHandler}
                onClose={closeReportHandler}
                onView={() => viewReportHandler()}
                declined={false}
                emailConfigured={emailConfigured}
            />
        </OverDetail>
    );

    const petCardView = (
        <Box className={classes.petCardContainer}>
            <PetCard
                petId={appointment?.pet?.id!}
                onClose={handlePetRefresh}
                onSaved={() => {
                    setReload(true);
                    loadBooking();
                }}
            />
        </Box>
    );

    const modalCompleteAppointment = (
        <CompleteAppointmentDialog
            onCancel={() => setShowModalCompleteAppointment(false)}
            onComplete={(sendSms: boolean) => completeHandler(completeReportData!, sendSms)}
        />
    );

    const cancelationReason = appointment?.cancelationReasonId
        ? masterData.cancelationReasons.find(reason => reason.id === appointment.cancelationReasonId)?.name
        : undefined;

    return (
        <Box>
            {!showPetCard ? (
                <Drawer
                    onClose={props.onBack}
                    open={true}
                    anchor="right"
                    className={clsx(classes.container, props.className)}
                >
                    {!showAddService && appointment?.status.id !== status.REQUESTED && (
                        <Box className={classes.iconContainer}>
                            <Box
                                className={classes.iconTimes}
                                onClick={props.onBack}
                            >
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    className={classes.icon}
                                />
                            </Box>
                        </Box>
                    )}
                    {!appointment ? (
                        loadingView
                    ) : (
                        <>
                            <Box className={classes.headerContainer}>
                                <BookingDetailHeader
                                    title={
                                        showAddService
                                            ? 'Add Service'
                                            : appointment.status.id === status.REQUESTED
                                            ? 'Online Booking Request'
                                            : 'Booking Detail'
                                    }
                                    appointment={appointment}
                                    booking={booking}
                                    uniquePet={uniquePet}
                                    loading={loadingConfirm || loadingAction}
                                    withoutReschedule={!!(appointments?.length > 1 && getAppointmentToConfirm())}
                                    appointmentToConfirm={getAppointmentToConfirm()}
                                    /* appointmentToConfirm={booking?.appointments.length === 1 ? getAppointmentToConfirm() : undefined} */
                                    onRebook={(selectedBooking: Booking) => props.onRebook!(selectedBooking)}
                                    onBook={() => setShowNewBooking(true)}
                                    onCancel={onCancelAppointmentHandler}
                                    onCancelBooking={onCancelBookingHandler}
                                    onDecline={declineHandler}
                                    onNoShow={noShowHandler}
                                    onRescheduleAppointment={rescheduleAppointmentHandler}
                                    onRescheduleBooking={rescheduleBookingHandler}
                                    onShowReport={showReportHandler}
                                    onShowInvoice={showInvoiceHandler}
                                    onUndo={(app: Appointment, statusId: number) => undoHandler(app, statusId)}
                                    onChangeStatus={status =>
                                        status === 5
                                            ? hasReportsPremiumAccess
                                                ? completeWithModalHandler(appointment)
                                                : completeHandler(appointment, false)
                                            : changeStatusSelectedHandler(status)
                                    }
                                    onShowAddReport={addReportHandler}
                                    onBack={showAddService ? backHandler : undefined}
                                    onCheckIn={() => changeStatusHandler(appointment?.id!, status.CHECKED_IN)}
                                    onStart={() => changeStatusHandler(appointment?.id!, status.IN_PROGRESS)}
                                    onCheckOut={checkOutHandler}
                                    onComplete={(appointmentToComplete: Appointment) =>
                                        hasReportsPremiumAccess
                                            ? completeWithModalHandler(appointmentToComplete)
                                            : completeHandler(appointmentToComplete, false)
                                    }
                                    onConfirm={confirmHandler}
                                    onClose={props.onBack}
                                />
                            </Box>
                            <OverlayScrollbarsComponent style={{ height: '100%' }}>
                                {appointment.status.id === status.CANCELLED && appointment.cancelationReasonId && (
                                    <Box className={classes.section}>
                                        <Info
                                            className={clsx(classes.infoContainer)}
                                            icon="circle-mark"
                                            textComponent={
                                                <Box
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'row',
                                                        gap: 4
                                                    }}
                                                >
                                                    <Typography
                                                        style={{
                                                            fontWeight: 600,
                                                            fontSize: 14
                                                        }}
                                                    >
                                                        Cancellation Reason:
                                                    </Typography>
                                                    <Typography
                                                        style={{
                                                            fontWeight: 400,
                                                            fontSize: 14
                                                        }}
                                                    >
                                                        {cancelationReason}
                                                    </Typography>
                                                </Box>
                                            }
                                        />
                                    </Box>
                                )}
                                {!showAddService ? (
                                    <Box className={classes.bookingDetailContainer}>
                                        {appointment.status.id === status.REQUESTED ? (
                                            <Box className={classes.section}>
                                                <BookingRequestDetail
                                                    pet={pet}
                                                    appointment={appointment}
                                                    onClose={props.onBack}
                                                />
                                            </Box>
                                        ) : (
                                            <>
                                                <Box className={classes.section}>
                                                    <ClientInfo
                                                        client={client}
                                                        deleted={booking?.customer?.deleted}
                                                        onShowClient={props.onShowClient}
                                                    />
                                                </Box>
                                                <Box className={clsx(classes.sectionGrey, classes.section)}>
                                                    <PetInfo
                                                        pet={pet}
                                                        client={client}
                                                        booking={booking}
                                                        appointment={appointment}
                                                        onShowPetCard={() => setShowPetCard(true)}
                                                        onPetSelect={handlePetSelect}
                                                    />
                                                </Box>
                                                <Box className={clsx(classes.sectionGrey, classes.section)}>
                                                    <DateInfo
                                                        appointment={appointment}
                                                        frequency={recurrentInfo}
                                                    />
                                                </Box>
                                                <Box className={clsx(classes.sectionGrey, classes.section)}>
                                                    <ServiceInfo
                                                        pet={pet}
                                                        petSelectedId={petSelectedId}
                                                        loading={loadingConfirm || loadingAction}
                                                        loadingService={loadingService}
                                                        booking={booking}
                                                        appointmentId={props.appointmentId}
                                                        showAddService={isAddingServicesEnabled(appointments)}
                                                        visibleStatus={!appointments.find(ap => ap.status.id === 1)}
                                                        onRemoveService={removeServiceHandler}
                                                        onAddReport={addReportHandler}
                                                        onViewReport={showReportHandler}
                                                        onAddService={() => setShowAddService(true)}
                                                        onBook={() => setShowNewBooking(true)}
                                                        onCancel={onCancelAppointmentHandler}
                                                        onCancelBooking={onCancelBookingHandler}
                                                        onDecline={declineHandler}
                                                        onNoShow={noShowHandler}
                                                        onRescheduleAppointment={rescheduleAppointmentHandler}
                                                        onRescheduleBooking={rescheduleBookingHandler}
                                                        onShowReport={showReportHandler}
                                                        onShowInvoice={showInvoiceHandler}
                                                        onUndo={(app: Appointment, statusId: number) =>
                                                            undoHandler(app, statusId)
                                                        }
                                                        onShowAddReport={addReportHandler}
                                                        onBack={showAddService ? backHandler : undefined}
                                                        onCheckIn={appointmentId =>
                                                            changeStatusHandler(appointmentId, status.CHECKED_IN)
                                                        }
                                                        onStart={appointmentId =>
                                                            changeStatusHandler(appointmentId, status.IN_PROGRESS)
                                                        }
                                                        onCheckOut={checkOutHandler}
                                                        onComplete={(appointmentToComplete: Appointment) =>
                                                            hasReportsPremiumAccess
                                                                ? completeWithModalHandler(appointmentToComplete)
                                                                : completeHandler(appointmentToComplete, false)
                                                        }
                                                        onConfirm={confirmHandler}
                                                    />
                                                </Box>
                                                <Box className={clsx(classes.sectionGrey, classes.section)}>
                                                    <Notes
                                                        notes={
                                                            booking?.appointments.find(ap => ap.id === appointment.id)
                                                                ?.notes ?? ''
                                                        }
                                                        onChange={changeNoteHandler}
                                                    />
                                                </Box>
                                            </>
                                        )}
                                    </Box>
                                ) : (
                                    <Box className={classes.bookingDetailContainer}>
                                        <Box className={classes.sectionContainer}>
                                            <ServiceSelector
                                                withoutBorder
                                                selectedService={service}
                                                clientId={appointment?.customer.id!}
                                                petId={appointment?.pet.id!}
                                                servicesSelected={getServicesUsed()}
                                                onSelectService={(serv: PetServiceWrapper) => setService(serv)}
                                            />
                                        </Box>

                                        {showShowStaff && (
                                            <Box className={classes.sectionContainer}>
                                                <StaffSelector
                                                    summary={false}
                                                    selectedStaff={staffSelected?.id}
                                                    loginStaff={auth.user!.staffId}
                                                    service={service}
                                                    firstLoad
                                                    onSelectStaff={(stf?: Staff) => setStaffSelected(stf)}
                                                    alerts={
                                                        service
                                                            ? alerts?.filter(alert => alert.alertType === 'staff')
                                                            : []
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </OverlayScrollbarsComponent>
                            <Box className={classes.footerContainer}>
                                <FooterComponent
                                    appointment={appointment}
                                    status={appointment?.status.id}
                                    title={showAddService ? 'Price' : 'Subtotal'}
                                    loading={
                                        showAddService
                                            ? loadingService
                                            : appointment?.status.id === status.REQUESTED
                                            ? loadingAction
                                            : false
                                    }
                                    isDetail={true}
                                    total={showAddService ? service?.price : getTotal(appointments)}
                                    buttonText={
                                        showAddService
                                            ? 'Add Service'
                                            : appointment.status.id === status.COMPLETED
                                            ? 'View Invoice'
                                            : 'Checkout'
                                    }
                                    disabledButton={showAddService ? !service || !staffSelected : isCheckoutDisabled()}
                                    deposit={
                                        showAddService
                                            ? undefined
                                            : booking?.deposits.find(
                                                  deposit => deposit.appointmentId === appointment?.id
                                              )?.amount
                                    }
                                    success={!showAddService}
                                    withoutButton={isCheckoutNotVisible()}
                                    onBook={
                                        showAddService
                                            ? saveServiceHandler
                                            : appointment.status.id === status.COMPLETED
                                            ? showInvoiceHandler
                                            : checkOutHandler
                                    }
                                    onChangeStatus={(status, sendNotification) =>
                                        changeBookingStatusHandler(appointment.bookingId, status, sendNotification)
                                    }
                                />
                            </Box>
                        </>
                    )}
                    {showCancelBooking && cancelBookingView}
                    {showConfirmation && confirmationView}
                    {showDecline && declinedView}
                    {showInvoice && invoiceView}
                    {showNewBooking && newBookingView}
                    {showReport && reportView}
                    {showEditReport && editReportView}
                    {checkoutAppointmentIds.length > 0 && checkoutView}

                    {showModalCheckout && bookingNotReadyForCheckoutModal}
                    {showAddReport && addReportView}
                    {showModalCompleteAppointment && modalCompleteAppointment}
                </Drawer>
            ) : (
                petCardView
            )}
        </Box>
    );
};

export default DetailBooking;
