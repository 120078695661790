import { createStyles, makeStyles } from '@material-ui/core';
import { SelectProps } from 'components/UI/V2/Forms/Select/Select';

export const useSelectStyles = makeStyles(() =>
    createStyles({
        root: <T,>(props: SelectProps<T>) => ({
            '& .MuiSelect-root': {
                height: 54,
                display: 'flex',
                alignItems: 'center',
                padding: '0px 0px 0px 24px'
            },
            '& .MuiInputLabel-formControl': {
                'fontSize': 16,

                '&.Mui-focused': {
                    color: 'inherit'
                },

                '&.MuiFormLabel-filled': {
                    display: 'none'
                },

                ...(props.variant === 'outlined' && {
                    paddingLeft: 24,
                    transform: 'translate(0, 20px) scale(1)'
                })
            },
            '& .MuiOutlinedInput-root': {
                // Focus state
                '&:hover, &.Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderWidth: 1
                    }
                },

                '& .MuiSelect-selectMenu': {
                    borderRadius: 30,
                    backgroundColor: 'transparent'
                },

                '& .MuiOutlinedInput-notchedOutline': {
                    'top': 0,
                    'borderRadius': 30,
                    'borderColor': '#D3D3D3 !important',

                    '& legend': {
                        display: 'none'
                    }
                }
            }
        }),
        icon: {
            right: 16,
            fontSize: 16,
            top: 'calc(50% - 8px)'
        }
    })
);
