import React, { useState } from 'react';
import { Box, Grid, Theme, Typography, createStyles, makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { MarketplacePaymentMethod } from '@spike/payments-model';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisH } from '@fortawesome/pro-regular-svg-icons';
import OptionsModal from 'components/Agreements/Modal/OptionsModal';

interface Props {
    id: number;
    active: boolean;
    onEdit: (card: MarketplacePaymentMethod) => void;
    card: MarketplacePaymentMethod;
    onOpenDropdown: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'height': 60,
            'width': '100%',
            'fontSize': 14,
            'fontWeight': 500,

            [theme.breakpoints.up('md')]: {
                height: 73
            },

            '&, & p': {
                fontSize: 14
            },

            '&:not(:last-child)': {
                borderBottom: 'solid 1px #D4D4D4'
            },

            [theme.breakpoints.up('sm')]: {
                '&, & p': {
                    fontSize: 16
                }
            }
        },
        leftCol: {
            display: 'flex',
            alignItems: 'center'
        },
        centerCol: {
            textAlign: 'right',

            [theme.breakpoints.down('xs')]: {
                paddingRight: 10
            },
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center'
            }
        },
        rightCol: {
            columnGap: 12,
            display: 'flex',
            position: 'relative',
            justifyContent: 'flex-end'
        },

        cardNumber: {
            fontWeight: 500,
            paddingLeft: 10
        },
        defaultBadge: {
            fontSize: 14,
            lineHeight: 1.43,
            color: '#5E8677',
            borderRadius: 30,
            padding: '3.5px 11px',
            display: 'inline-block',
            backgroundColor: '#E9F0ED'
        },
        dropdownBtn: {
            border: 0,
            fontSize: 22,
            cursor: 'pointer',
            backgroundColor: 'transparent',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row'
        },
        dropdown: {
            'top': '120%',
            'zIndex': 100,
            'borderRadius': 14,
            'overflow': 'hidden',
            'position': 'absolute',
            'backgroundColor': '#fff',
            'border': 'solid 1px #000',

            '& button': {
                'border': 0,
                'width': '100%',
                'fontSize': 14,
                'fontWeight': 500,
                'lineHeight': 1.97,
                'cursor': 'pointer',
                'appearance': 'none',
                'padding': '7.5px 20px',
                'backgroundColor': '#fff',

                '& svg': {
                    marginRight: 4
                }
            }
        },

        desktop: {
            [theme.breakpoints.only('xs')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('sm')]: {
                display: 'none'
            }
        }
    })
);

const path = '/images/creditCards/';

const icons: Map<string, string> = new Map([
    ['VISA', path.concat('icon-visa.svg')],
    ['default', path.concat('icon-credit-card.svg')],
    ['American Express', path.concat('icon-amex.svg')],
    ['MsaterCard', path.concat('icon-mastercard.svg')],
    ['Diners Club', path.concat('icon-diners-club.svg')]
]);

export const CardRow: React.FunctionComponent<Props> = props => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [showDropdown, setShowDropdown] = useState(false);

    const editHandler = () => {
        setShowDropdown(false);
        props.onEdit(props.card);
    };

    return (
        <Grid
            className={classes.root}
            container
            alignItems="center"
        >
            <Grid
                item
                xs={12}
                className={classes.mobile}
            >
                {props.card.holderName}
            </Grid>

            <Grid
                item
                xs={6}
                sm={3}
                className={classes.leftCol}
            >
                <img
                    src={icons.get(props.card.name) || icons.get('default')}
                    alt="Card icon"
                />

                <Typography className={classes.cardNumber}>•••• {props.card.lastDigits}</Typography>
            </Grid>
            <Grid
                item
                xs={5}
                sm={5}
                className={classes.centerCol}
            >
                <Typography>
                    Exp. {props.card.expirationMonth}/{props.card.expirationYear}
                </Typography>
            </Grid>
            <Grid
                item
                xs={3}
                alignItems="center"
                className={clsx(classes.rightCol, classes.desktop)}
            >
                {props.card.holderName}
                <Box className={classes.defaultBadge}>Default</Box>
            </Grid>
            <Grid
                item
                xs={1}
                alignItems="center"
                className={clsx(classes.rightCol)}
            >
                {isMobile ? (
                    <OptionsModal
                        onClick={editHandler}
                        options={[{ id: 'edit', title: 'Edit', icon: faPencil }]}
                        actionComponent={
                            <button
                                type="button"
                                aria-label="Open dropdown"
                                className={classes.dropdownBtn}
                            >
                                <FontAwesomeIcon icon={faEllipsisH} />
                            </button>
                        }
                    />
                ) : (
                    <button
                        type="button"
                        aria-label="Open dropdown"
                        className={classes.dropdownBtn}
                        onClick={() => setShowDropdown(!showDropdown)}
                    >
                        <FontAwesomeIcon icon={faEllipsisH} />
                    </button>
                )}
                {!isMobile && showDropdown && (
                    <Box className={classes.dropdown}>
                        <button
                            type="button"
                            aria-label="Edit card"
                            onClick={editHandler}
                            className={classes.dropdownBtn}
                        >
                            <FontAwesomeIcon icon={faPencil} />
                            <span>Edit</span>
                        </button>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};
