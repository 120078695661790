import { faPaw } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export interface PetsCountBadgeProps {
    petsCount: number;
    maxPetsCount: number;
    className?: string;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            'gap': 4,
            'margin': 0,
            'padding': 5,
            'fontSize': 12,
            'lineHeight': 1,
            'fontWeight': 500,
            'color': '#000000',
            'borderRadius': 3,
            'display': 'inline-flex',
            'alignItems': 'center',
            'backgroundColor': '#F1F1F1',

            '& svg': {
                fontSize: 14
            }
        }
    })
);

export const PetsCountBadge: React.FC<PetsCountBadgeProps> = props => {
    const classes = useStyles();

    return (
        <div className={clsx(classes.root, props.className)}>
            <FontAwesomeIcon icon={faPaw} />
            {`${props.petsCount}/${props.maxPetsCount}`}
        </div>
    );
};
