import React, { createRef } from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { ScheduleTypeCalendarToolbar } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarToolbar';
import FullCalendar from '@fullcalendar/react';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import { useMarketplace, useTimeZone } from 'hooks';
import moment, { Moment } from 'moment-timezone';
import { Period } from '@spike/model';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ScheduleTypeCalendarSlotLabel } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarSlotLabel';
import { getDateBusinessHours } from 'components/Calendar3/FullCalendar/FullCalendarUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { fetchStaffThunk } from 'actions/staff/StaffActions';
import { ScheduleTypeCalendarStaffCell } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarStaffCell';
import { ScheduleTypeCalendarEvent } from 'components/Marketplace/BusinessSettings/ScheduleType/ScheduleTypeCalendar/ScheduleTypeCalendarEvent';
import { MultiSlotDrawer } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawer';
import Staff, { StaffCustomDaySlot } from 'model/Staff';
import { getDateRange } from 'utils/DateUtils';
import { getStaffCustomDayFromDate } from 'utils/StaffScheduleUtils';
import {
    getDefaultSlotDate,
    getStaffSlotsByPeriod,
    isDefaultSlot
} from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { EventInput } from '@fullcalendar/core';
import { AddSlot } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/AddSlot';
import { DefaultSlots } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/DefaultSlots';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            marginTop: 24
        },
        slotLabel: {
            'zIndex': 100,
            'position': 'relative',
            'height': '50px !important',
            'borderTop': 'none !important',
            'borderLeft': 'none !important',
            'borderRight': 'none !important',
            'borderBottom': '1px solid #D8D8D8 !important',
            'backgroundColor': '#FFFFFF',

            '& > div': {
                height: '100%'
            }
        },
        calendarWrapper: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: -15,
                marginRight: -15
            },

            '& .fc-timegrid-axis': {
                borderRight: 0
            },
            '& table.fc-scrollgrid': {
                border: 0
            },
            '& .fc-resource-timeline-divider': {
                width: 0,
                border: 0,
                visibility: 'hidden',
                background: 'transparent'
            },
            '& .fc-scrollgrid-section-header th:first-child': {
                borderRight: 0
            },
            '& .fc-timeline-more-link': {
                'padding': '0px 8px',
                'pointerEvents': 'none',
                'backgroundColor': 'transparent',

                '& > div': {
                    padding: 0,
                    fontSize: 12,
                    lineHeight: '24px',
                    color: '#000',
                    fontWeight: 500
                }
            }
        },
        calendarPointer: {
            'zIndex': 1,
            'color': '#000',
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'center',
            'pointerEvents': 'none',
            'position': 'fixed',
            'backgroundColor': '#ffffff',
            'border': '1px solid #000000',

            '& p': {
                margin: 0,
                fontSize: 14,
                fontWeight: 500
            }
        },
        resourcesLabel: {
            'margin': 0,
            'height': 50,
            'fontSize': 14,
            'fontWeight': 500,
            'fontFamily': 'Poppins',
            'verticalAlign': 'bottom !important',

            '& .fc-scrollgrid-sync-inner': {
                padding: '0px 0px 0px 24px'
            }
        },
        event: {
            border: 0,
            padding: 5,
            pointerEvents: 'none',
            margin: '0px !important',
            backgroundColor: 'transparent'
        }
    })
);

export const getStaffDaysOfWeek = (staff: Staff, period: Period) => {
    return staff.schedule.customDays.filter(day => day.from.isBetween(period.from, period.to, 'day', '[]'));
};

export const ScheduleTypeCalendar: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const today = moment().tz(timeZone);

    const [mousePosition, setMousePosition] = React.useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const [clickedDate, setClickedDate] = React.useState<Moment>();
    const [selectedStaff, setSelectedStaff] = React.useState<Staff>();
    const [showCustomDayDrawer, setShowCustomDayDrawer] = React.useState<boolean>(false);
    const [showDefaultSlotsDrawer, setShowDefaultSlotsDrawer] = React.useState<boolean>(false);

    const [period, setPeriod] = React.useState<Period>({
        from: today.clone().startOf('week'),
        to: today.clone().endOf('week')
    });

    const staffState = useSelector((state: RootState) => state.staff);

    const fullCalendarRef = createRef<FullCalendar>();
    const calendarPointerRef = createRef<HTMLDivElement>();

    const staffWithBusinessHours = React.useMemo(() => {
        const dateRange = getDateRange(period.from, period.to, 'days');

        const weekBusinessHours = dateRange.reduce((map, date) => {
            const weekDay = date.clone().weekday();
            const businessHours = getDateBusinessHours(date, marketplace.schedule);

            map.set(
                weekDay ?? -1,
                businessHours
                    ? {
                          ...businessHours,
                          date
                      }
                    : null
            );
            return map;
        }, new Map<number, (Period & { date: Moment }) | null>());

        return staffState.staff.map(staff => {
            const staffBusinessHours = Array.from(weekBusinessHours.values())
                .filter((businessHours): businessHours is Period & { date: Moment } => {
                    if (!businessHours) {
                        return false;
                    }

                    const staffCustomDay = getStaffCustomDayFromDate(staff.schedule, businessHours.date);

                    return staffCustomDay ? staffCustomDay.on : true;
                })
                .map(businessHour => {
                    return {
                        daysOfWeek: [businessHour.date.weekday()],
                        startTime: businessHour.from.format('HH:mm:ss'),
                        endTime: businessHour.to.format('HH:mm:ss')
                    };
                })
                .filter(businessHour => {
                    return businessHour.startTime !== '00:00:00' && businessHour.endTime !== '00:00:00';
                });

            return {
                id: staff.id?.toString() ?? '',
                title: staff.person.firstName,
                businessHours: staffBusinessHours,
                extendedProps: {
                    ...staff
                }
            };
        });
    }, [period, staffState.staff]);

    const staffSlotsAsEvents = React.useMemo(() => {
        return staffState.staff.reduce<Array<EventInput>>((acc, staff) => {
            const slots = getStaffSlotsByPeriod(staff.slots, period);

            return [
                ...acc,
                ...slots.map(slot => {
                    const slotDate = isDefaultSlot(slot) ? getDefaultSlotDate(period.to, slot) : slot.date;

                    const id = `${staff.id}-${slotDate.format('YYYY-MM-DD')}-${slot.time.replace(':', '')}`;
                    const from = slotDate.clone();
                    const to = slotDate.clone().add(1, 'hour');

                    return {
                        id,
                        resourceId: staff.id?.toString() ?? '',
                        title: slotDate.format('hh:mm A'),
                        start: from.toDate(),
                        end: to.toDate(),
                        extendedProps: {
                            from,
                            to,
                            petsCount: slot.petsCount
                        }
                    };
                })
            ];
        }, [] as Array<EventInput>);
    }, [period, staffState.staff]);

    const goToTodayHandler = () => {
        setPeriod({
            from: today.clone().startOf('week'),
            to: today.clone().endOf('week')
        });
    };

    const dateClickHandler = (info: DateClickArg) => {
        const fullCalendarClickedDate = moment().tz(timeZone);

        fullCalendarClickedDate.set({
            date: info.date.getDate(),
            month: info.date.getMonth(),
            year: info.date.getFullYear()
        });

        setSelectedStaff(staffState.staff.find(staff => staff.id === Number(info.resource?.id)));
        setClickedDate(fullCalendarClickedDate);
        setShowCustomDayDrawer(true);
    };

    const mouseMoveHandler = (event: MouseEvent) => {
        setMousePosition({ x: event.clientX, y: event.clientY });
    };

    /*
     * Calculate the calendar pointer position
     */
    React.useEffect(() => {
        if (!calendarPointerRef.current) {
            return;
        }

        // Get all column and row elements
        const columns = document.querySelectorAll('.fc-timeline-slot-lane');
        const rows = document.querySelectorAll('.fc-timeline-lane');

        // Find intersecting column
        const intersectingColumn = Array.from(columns).find(column => {
            const rect = column.getBoundingClientRect();
            return (
                mousePosition.x >= rect.left &&
                mousePosition.x <= rect.right &&
                mousePosition.y >= rect.top &&
                mousePosition.y <= rect.bottom
            );
        });

        // Find intersecting row
        const intersectingRow = Array.from(rows).find(row => {
            const rect = row.getBoundingClientRect();
            return (
                mousePosition.x >= rect.left &&
                mousePosition.x <= rect.right &&
                mousePosition.y >= rect.top &&
                mousePosition.y <= rect.bottom
            );
        });

        // If both column and row are found
        if (intersectingColumn && intersectingRow) {
            const columnRect = intersectingColumn.getBoundingClientRect();
            const rowRect = intersectingRow.getBoundingClientRect();

            // Calculate intersection box coordinates
            const intersectionLeft = columnRect.left;
            const intersectionTop = rowRect.top;
            const intersectionWidth = columnRect.width;
            const intersectionHeight = rowRect.height;

            // Position and size the intersection div
            calendarPointerRef.current.style.left = `${intersectionLeft}px`;
            calendarPointerRef.current.style.top = `${intersectionTop}px`;
            calendarPointerRef.current.style.width = `${intersectionWidth}px`;
            calendarPointerRef.current.style.height = `${intersectionHeight}px`;

            // Make the div visible
            calendarPointerRef.current.style.display = 'flex';
        } else {
            // Hide the div if not hovering an intersection
            calendarPointerRef.current.style.display = 'none';
        }
    }, [mousePosition]);

    /*
     * Change fullcalendar week to the period's start date
     */
    React.useEffect(() => {
        fullCalendarRef.current?.getApi().gotoDate(period.from.toDate());
    }, [period]);

    /*
     * Update the selected staff if the staff has been updated
     */
    React.useEffect(() => {
        const staffUpdated = staffState.staff.find(staff => staff.id === selectedStaff?.id);

        if (staffUpdated) {
            setSelectedStaff(staffUpdated);
        }
    }, [staffState.staff]);

    React.useEffect(() => {
        setTimeout(() => {
            fullCalendarRef.current?.render();
            window.dispatchEvent(new Event('resize'));
        }, 200);
    }, [fullCalendarRef]);

    React.useEffect(() => {
        dispatch(fetchStaffThunk());

        window.addEventListener('scroll', () => setMousePosition({ x: 0, y: 0 }), true);
        window.addEventListener('mousemove', mouseMoveHandler);

        return () => {
            window.removeEventListener('scroll', () => setMousePosition({ x: 0, y: 0 }));
            window.removeEventListener('mousemove', mouseMoveHandler);
        };
    }, []);

    const fullCalendarComponent = React.useMemo(
        () => (
            <FullCalendar
                ref={fullCalendarRef}
                plugins={[interactionPlugin, momentTimezonePlugin, resourceTimelinePlugin]}
                /*
                 * License
                 */
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                /*
                 * Views options
                 */
                initialView="resourceTimelineWeek"
                /*
                 * Options
                 */
                height="auto"
                timeZone={timeZone}
                /*
                 * Header
                 */
                headerToolbar={false}
                /*
                 * Resources
                 */
                resources={staffWithBusinessHours}
                resourceAreaWidth="160px"
                resourceAreaHeaderContent={'Staff'}
                resourceAreaHeaderClassNames={[classes.resourcesLabel]}
                resourceLabelContent={resource => (
                    <ScheduleTypeCalendarStaffCell
                        period={period}
                        {...resource}
                    />
                )}
                /*
                 * Slots
                 */
                slotMinWidth={130}
                slotEventOverlap={false}
                slotDuration={{ day: 1 }}
                slotLabelClassNames={[classes.slotLabel]}
                slotLabelContent={props => (
                    <ScheduleTypeCalendarSlotLabel
                        staffs={staffState.staff}
                        {...props}
                    />
                )}
                /*
                 * Events
                 */
                dayMaxEvents={3}
                eventMaxStack={3}
                dayMaxEventRows={true}
                events={staffSlotsAsEvents}
                eventClassNames={[classes.event]}
                eventContent={event => <ScheduleTypeCalendarEvent {...event} />}
                /*
                 * More link
                 */
                moreLinkContent={({ num }) => <div>{num} more</div>}
                /*
                 * Events
                 */
                dateClick={dateClickHandler}
            />
        ),
        [period, staffState.staff]
    );

    return (
        <>
            <div className={classes.root}>
                <ScheduleTypeCalendarToolbar
                    selectedWeek={period}
                    onChange={setPeriod}
                    onGoToToday={goToTodayHandler}
                    onSetupDefaultSlots={() => setShowDefaultSlotsDrawer(true)}
                />

                <div
                    className={classes.calendarWrapper}
                    onMouseLeave={() => setMousePosition({ x: 0, y: 0 })}
                >
                    {fullCalendarComponent}

                    <div
                        ref={calendarPointerRef}
                        className={classes.calendarPointer}
                    >
                        <Typography>
                            <FontAwesomeIcon icon={faPlus} />
                            Add Slot
                        </Typography>
                    </div>
                </div>
            </div>

            <MultiSlotDrawer
                open={showCustomDayDrawer}
                onClose={() => setShowCustomDayDrawer(false)}
            >
                <AddSlot
                    period={period}
                    defaultDate={clickedDate}
                    selectedStaff={selectedStaff}
                    onClose={() => setShowCustomDayDrawer(false)}
                />
            </MultiSlotDrawer>

            <MultiSlotDrawer
                open={showDefaultSlotsDrawer}
                onClose={() => setShowDefaultSlotsDrawer(false)}
            >
                <DefaultSlots onClose={() => setShowDefaultSlotsDrawer(false)} />
            </MultiSlotDrawer>
        </>
    );
};
