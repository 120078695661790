import React from 'react';
import { Select as MUISelect, FormControl, InputLabel, MenuItem } from '@material-ui/core';
import { SelectProps as MUISelectProps } from '@material-ui/core/Select';
import { useSelectStyles } from 'components/UI/V2/Forms/Select/Select.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

export interface SelectOption<T> {
    label: string;
    value: string;
    data?: T;
}

export interface SelectProps<T> extends MUISelectProps {
    icon?: IconProp;
    wrapperClassName?: string;
    options: Array<SelectOption<T>>;
    renderOption?: (option: SelectOption<T>) => React.ReactNode;
}

const defaultProps = {
    variant: 'outlined'
};

const SelectIcon: React.FunctionComponent<{ icon?: IconProp; className?: string }> = props => {
    return (
        <FontAwesomeIcon
            icon={props.icon || faChevronDown}
            className={clsx('MuiSvgIcon-root', 'MuiSelect-icon', 'MuiSelect-iconOutlined', props.className)}
        />
    );
};

export const Select = <T,>({ options, wrapperClassName, renderOption, ...props }: SelectProps<T>) => {
    const classes = useSelectStyles({ ...(defaultProps as SelectProps<unknown>), ...props });

    return (
        <FormControl
            fullWidth
            className={clsx(classes.root, wrapperClassName)}
        >
            <InputLabel shrink={false}>{props.label}</InputLabel>

            <MUISelect
                MenuProps={{
                    style: { maxHeight: 300 },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    },
                    getContentAnchorEl: null
                }}
                variant={props.variant || 'outlined'}
                IconComponent={() => SelectIcon({ icon: props.icon, className: classes.icon })}
                {...props}
            >
                {options.map(option => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                    >
                        {renderOption ? renderOption(option) : option.label}
                    </MenuItem>
                ))}
            </MUISelect>
        </FormControl>
    );
};
