import React from 'react';
import { useDropdownSelectStyles } from 'components/UI/V2/DropdownSelect/DropdownSelect.styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';
import { ClickAwayListener } from '@material-ui/core';

export type DropdownSelectOption<T> = {
    label: string;
    value: T;
};

export interface DropdownSelectProps<T> {
    id?: string;
    label: string;
    value?: T;
    className?: string;
    readOnly?: boolean;
    options: Array<DropdownSelectOption<T>>;
    disabledOptions?: Array<T>;
    selectFirstOption?: boolean;
    onChange?: (value: T) => void;
}

export const DropdownSelect = <T,>({ readOnly = true, ...props }: DropdownSelectProps<T>) => {
    const classes = useDropdownSelectStyles();

    const [isOpen, setIsOpen] = React.useState(false);

    const selectedOption = React.useMemo(() => {
        return props.options.find(option => option.value === props.value);
    }, [props.options, props.value]);

    const changeValueHandler = (value: T) => {
        if (props.disabledOptions?.some(disabledOption => disabledOption === value)) {
            return;
        }

        props.onChange?.(value);
        setIsOpen(false);
    };

    React.useEffect(() => {
        if (props.selectFirstOption && !props.value && props.options.length > 0) {
            props.onChange?.(props.options[0].value);
        }
    }, [props.selectFirstOption, props.value, props.options]);

    return (
        <div className={clsx(classes.root, props.className)}>
            <label className={classes.label}>{selectedOption?.label || props.label}</label>

            <button
                type="button"
                id={props.id}
                className={clsx(classes.dropdownToggle, readOnly && classes.hidden)}
                onClick={() => setIsOpen(!isOpen)}
            >
                <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} />
            </button>

            {isOpen && (
                <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                    <div className={classes.dropdownMenu}>
                        {props.options.map((option, index) => (
                            <div
                                key={index}
                                className={clsx(classes.dropdownItem, {
                                    [classes.dropdownItemDisabled]: props.disabledOptions?.some(
                                        disabledOption => disabledOption === option.value
                                    )
                                })}
                                onClick={() => changeValueHandler(option.value)}
                            >
                                {option.label}
                            </div>
                        ))}

                        {props.options.length === 0 && (
                            <div className={clsx(classes.dropdownItem, classes.noOptions)}>No options</div>
                        )}
                    </div>
                </ClickAwayListener>
            )}
        </div>
    );
};
