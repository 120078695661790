import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Theme, createStyles, makeStyles } from '@material-ui/core';
import { InvoiceStatus } from './InvoiceStatus';
import { AdminInvoice } from 'model/Subscriptions';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowDown } from '@fortawesome/pro-regular-svg-icons';

interface InvoicesListProps {
    invoices: Array<AdminInvoice>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'overflowX': 'scroll',

            '&::-webkit-scrollbar': {
                display: 'none'
            }
        },
        tableHead: {
            'borderBottom': 'solid 2px #000',

            '& .MuiTableCell-head': {
                'fontSize': 16,
                'lineHeight': 1.5,
                'fontWeight': 600,

                '&:first-child': {
                    'left': 0,
                    'position': 'sticky',

                    [theme.breakpoints.up('md')]: {
                        paddingLeft: 35
                    },

                    '&:before': {
                        'content': `''`,
                        'top': 0,
                        'left': 0,
                        'right': 0,
                        'bottom': 0,
                        'zIndex': -1,
                        'display': 'block',
                        'position': 'absolute',
                        'backgroundColor': '#fff',
                        'boxShadow': '0px 0px 0px 0px transparent',
                        'transition': 'box-shadow 0.15s ease-out',

                        '.scrolled &': {
                            boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
                        }
                    }
                },

                '&:last-child': {
                    [theme.breakpoints.only('xs')]: {
                        minWidth: 80
                    }
                }
            }
        },
        tableBody: {
            'whiteSpace': 'nowrap',

            '& .MuiTableCell-body': {
                'height': 54,
                'fontSize': 16,
                'lineHeight': 1,
                'borderBottomColor': '#D4D4D4',

                [theme.breakpoints.up('md')]: {
                    height: 73
                },

                '&:first-child': {
                    'left': 0,
                    'fontWeight': 500,
                    'paddingLeft': 35,
                    'position': 'sticky',

                    '&:before': {
                        'content': `''`,
                        'top': 1,
                        'left': 0,
                        'right': 0,
                        'bottom': 1,
                        'zIndex': -1,
                        'display': 'block',
                        'position': 'absolute',
                        'backgroundColor': '#fff',
                        'boxShadow': '0px 0px 0px 0px transparent',
                        'transition': 'box-shadow 0.15s ease-out',

                        '.scrolled &': {
                            boxShadow: '8px 0px 10px 0px rgba(0, 0, 0, 0.08)'
                        }
                    }
                }
            },

            '& .MuiTableRow-root': {
                '&:last-child': {
                    '& .MuiTableCell-body': {
                        borderBottom: 0
                    }
                }
            },

            '& b, & a': {
                fontWeight: 500
            },
            '& a': {
                'fontSize': 16,
                'color': '#222',
                'lineHeight': 1.5,
                'position': 'relative',
                'display': 'inline-block',
                'textDecoration': 'none',

                [theme.breakpoints.up('md')]: {
                    borderBottom: 'solid 1.5px #222'
                },

                '& span': {
                    [theme.breakpoints.down('sm')]: {
                        display: 'none'
                    }
                },
                '& svg': {
                    [theme.breakpoints.up('md')]: {
                        display: 'none'
                    }
                }
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        }
    })
);

const InvoiceRow: React.FunctionComponent<{
    invoice: AdminInvoice;
}> = ({ invoice }) => {
    return (
        <TableRow>
            <TableCell>
                <b>{invoice.number}</b>
            </TableCell>
            <TableCell>{invoice.createdAt.format('LL')}</TableCell>
            <TableCell>
                <InvoiceStatus
                    paid={invoice.paidInFull}
                    unpaid={!invoice.paidInFull}
                    // pending={status === 'pending'}
                />
            </TableCell>
            <TableCell>${invoice.total}</TableCell>
            <TableCell>
                {invoice.pdfUrl && (
                    <>
                        <a
                            download
                            target="_blank"
                            rel="noreferrer"
                            href={invoice.pdfUrl}
                        >
                            <span>Download</span>
                            <FontAwesomeIcon icon={faCloudArrowDown} />
                        </a>
                    </>
                )}
            </TableCell>
        </TableRow>
    );
};

export const InvoicesList: React.FunctionComponent<InvoicesListProps> = ({ invoices }) => {
    const classes = useStyles();
    const ref = useRef<HTMLDivElement>(null);

    const [scrollPos, setScrollPos] = useState(0);

    // The scroll listener
    const handleScroll = useCallback((event: Event) => {
        const div = event.target as HTMLDivElement;

        setScrollPos(div.scrollLeft);
    }, []);

    // Attach the scroll listener to the div
    useEffect(() => {
        const div = ref.current;

        div?.addEventListener('scroll', handleScroll);
    }, [handleScroll]);

    return (
        <div
            className={classes.root}
            ref={ref}
        >
            <Table
                className={clsx({
                    scrolled: scrollPos > 0
                })}
            >
                <TableHead className={classes.tableHead}>
                    <TableRow>
                        <TableCell>Invoice</TableCell>
                        <TableCell>Billing Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Amount</TableCell>
                        <TableCell>
                            <span className={classes.mobile}>Actions</span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody className={classes.tableBody}>
                    {invoices.map((invoice, i) => (
                        <InvoiceRow
                            key={i}
                            invoice={invoice}
                        />
                    ))}
                </TableBody>
            </Table>
        </div>
    );
};
