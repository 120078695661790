import { FunctionComponent, useState, useRef, Fragment } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box, IconButton, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrashCan } from '@fortawesome/pro-regular-svg-icons';
import { CropperImage } from 'components/UI';
import clsx from 'clsx';

export interface Props {
    imageUrl?: string;
    onChangeImage: (imageFile: File | undefined, image: string | undefined) => void;
    maxSize: number;
    maxWidth: number;
    maxHeight: number;
    imageTypes: Array<string>;
    className?: string;
    containerClassName?: string;
    type?: 'square' | 'circle';
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column'
        },
        imageContainer: {
            borderRadius: (props: Props) => (props.type === 'square' ? '14.15px' : '50%'),
            height: '134px',
            width: '134px'
        },
        emptyImage: {
            alignItems: 'center',
            backgroundColor: '#F1F1F1',
            borderRadius: (props: Props) => (props.type === 'square' ? '14.15px' : '50%'),
            color: '#D3D3D3',
            cursor: 'pointer',
            display: 'flex',
            fontSize: '33px',
            height: '100%',
            justifyContent: 'center',
            width: '100%'
        },
        image: {
            backgroundImage: (props: Props) => 'url(' + props.imageUrl + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            borderRadius: (props: Props) => (props.type === 'square' ? '14.15px' : '50%'),
            height: '100%',
            position: 'relative',
            width: '100%'
        },
        trashButton: {
            '&:hover': {
                backgroundColor: '#FAFAFA'
            },
            'backgroundColor': '#FFFFFF',
            'fontSize': '14px',
            'height': '31.36px',
            'position': 'absolute',
            'right': '5%',
            'top': '5%',
            'width': '31.36px'
        },
        descriptionContainer: {
            marginTop: '16px'
        },
        description: {
            color: '#7A7A7A',
            fontSize: '14px',
            paddingBottom: '8px',
            lineHeight: '21px'
        },
        error: {
            color: '#EF4F57',
            fontSize: '14px',
            lineHeight: '14px',
            marginTop: '16px'
        }
    })
);

export const UploadImage: FunctionComponent<Props> = props => {
    const classes = useStyles(props);

    const [showCropper, setShowCropper] = useState(false);
    const [error, setError] = useState<string>();
    const [selectedImage, setSelectedImage] = useState<string>();
    const [fileName, setFileName] = useState<string>();
    const input = useRef<HTMLInputElement>(null);

    const imageTypeDescription = () => {
        return props.imageTypes.map(imgType => imgType.toUpperCase()).join(' or ');
    };

    const handleChangeClick = () => {
        input.current?.click();
    };

    const handleRemoveEdit = () => {
        props.onChangeImage && props.onChangeImage(undefined, undefined);
    };

    const selectImageHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (!file) return;

        const reader = new FileReader();
        const isFileSizeValid = file.size / 1024 <= props.maxSize;
        const isFileTypeValid = props.imageTypes.includes(file.type.split('/')[1]);

        reader.onload = () => {
            if (reader.readyState === 2) {
                if (isFileSizeValid && isFileTypeValid) {
                    setSelectedImage(reader.result?.toString());
                    setFileName(file.name);
                    setShowCropper(true);
                    setError('');
                } else {
                    const errorMessage = !isFileSizeValid
                        ? `Image size exceeds the ${props.maxSize / 1024}MB limit.`
                        : `Unsupported file format. Please upload a ${imageTypeDescription()} file.`;
                    setError(errorMessage);
                }
            }
        };

        reader.readAsDataURL(file);
    };

    const cropHandler = (file: File) => {
        const reader = new FileReader();

        reader.onload = () => {
            if (reader.readyState === 2) {
                props.onChangeImage && props.onChangeImage(file, reader.result?.toString()!);
                setShowCropper(false);
            }
        };
        reader.readAsDataURL(file);
    };

    return (
        <>
            <Box className={clsx(classes.container, props.className)}>
                <input
                    style={{ display: 'none' }}
                    type={'file'}
                    ref={input}
                    onChange={e => selectImageHandler(e)}
                    accept={props.imageTypes.map(ext => `.${ext}`).join(',')}
                    value={''}
                />

                <Box className={clsx(classes.imageContainer, props.containerClassName)}>
                    {props.imageUrl ? (
                        <Box className={classes.image}>
                            <IconButton
                                className={classes.trashButton}
                                onClick={handleRemoveEdit}
                            >
                                <FontAwesomeIcon icon={faTrashCan} />
                            </IconButton>
                        </Box>
                    ) : (
                        <Box
                            className={classes.emptyImage}
                            onClick={handleChangeClick}
                        >
                            <FontAwesomeIcon icon={faImage} />
                        </Box>
                    )}
                </Box>

                <Box className={classes.descriptionContainer}>
                    <Typography className={classes.description}>{`Recommended Ratio: ${props.maxWidth}x${
                        props.maxHeight
                    }, ${imageTypeDescription()}`}</Typography>
                    <Typography className={clsx(classes.description)}>{`Max Size: ${
                        props.maxSize / 1024
                    }MB`}</Typography>
                    {error && <Typography className={clsx(classes.error)}>{error}</Typography>}
                </Box>
            </Box>
            {showCropper && (
                <CropperImage
                    image={selectedImage!}
                    fileName={fileName!}
                    cropWidth={props.maxWidth}
                    cropHeight={props.maxHeight}
                    onClose={() => setShowCropper(false)}
                    onCrop={cropHandler}
                    viewScale={props.type === 'square' ? 0.5 : 1}
                />
            )}
        </>
    );
};

export default UploadImage;
