import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ToolbarButton from 'components/UI/Toolbar/ToolbarButton';
import { ToolbarItem } from 'components/UI/Toolbar/ToolbarItem';
import { faCalendarAlt, faTimesCircle, faFileSearch, faFile, faRedo } from '@fortawesome/pro-light-svg-icons';
import { NoShowToolbarItem } from 'components/UI/Toolbar/NoShowToolbarItem';
import { useHasPremiumAccess } from 'hooks';
import { status } from '@spike/appointment-model';
import Booking from '@spike/booking-model';
import { useHistory } from 'react-router-dom';
import { BusinessPlanPath } from 'routes/Paths';
interface Props {
    withoutReschedule?: boolean;
    isDetail?: boolean;
    booking?: Booking;
    singleBooking?: boolean;
    customerDeleted?: boolean;
    onReschedule?: () => void;
    onCancel?: () => void;
    onNoShow?: () => void;
    onShowReport?: () => void;
    onShowAddReport?: () => void;
    onRebook?: () => void;
    className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            position: 'relative',
            display: 'flex'
        },
        button: {
            'color': '#222222',
            'borderColor': '#92B4A7',
            'backgroundColor': '#E9F0ED',
            [theme.breakpoints.only('xs')]: {
                paddingLeft: '6px',
                paddingRight: '6px',
                height: '30px',
                width: '34px'
            },
            [theme.breakpoints.only('sm')]: {
                paddingLeft: '6px',
                paddingRight: '6px',
                height: '30px',
                width: '34px'
            },
            [theme.breakpoints.only('md')]: {
                paddingLeft: '8px',
                paddingRight: '8px',
                height: '38px',
                width: '56px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingLeft: '11px',
                paddingRight: '11px',
                height: '40px',
                width: '66px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingLeft: '22px',
                paddingRight: '22px',
                height: '45px',
                width: '112px'
            },
            '&:hover': {
                color: '#222222',
                borderColor: '#92B4A7',
                backgroundColor: '#E9F0ED'
            },
            '& .MuiButton-label': {
                fontWeight: 'normal',
                [theme.breakpoints.only('xs')]: {
                    fontSize: '12px'
                },
                [theme.breakpoints.only('sm')]: {
                    fontSize: '12px'
                },
                [theme.breakpoints.only('md')]: {
                    fontSize: '13px'
                },
                [theme.breakpoints.only('lg')]: {
                    fontSize: '14px'
                },
                [theme.breakpoints.only('xl')]: {
                    fontSize: '16px'
                }
            }
        },
        list: {
            position: 'absolute',
            borderWidth: '1px',
            borderStyle: 'solid',
            backgroundColor: 'white',
            zIndex: 10,
            [theme.breakpoints.only('xs')]: {
                top: '40px',
                borderRadius: '8px',
                paddingLeft: '9px',
                paddingRight: '9px',
                paddingBottom: '11px'
            },
            [theme.breakpoints.only('sm')]: {
                top: '40px',
                borderRadius: '8px',
                paddingLeft: '9px',
                paddingRight: '9px',
                paddingBottom: '11px'
            },
            [theme.breakpoints.only('md')]: {
                top: '45px',
                borderRadius: '10px',
                paddingLeft: '14px',
                paddingRight: '14px',
                paddingBottom: '18px'
            },
            [theme.breakpoints.only('lg')]: {
                top: '50px',
                borderRadius: '12px',
                paddingLeft: '18px',
                paddingRight: '18px',
                paddingBottom: '24px'
            },
            [theme.breakpoints.only('xl')]: {
                top: '60px',
                borderRadius: '14px',
                paddingLeft: '28px',
                paddingRight: '28px',
                paddingBottom: '37px'
            }
        },
        titleContainer: {
            [theme.breakpoints.only('xs')]: {
                paddingTop: '8px',
                paddingBottom: '7px'
            },
            [theme.breakpoints.only('sm')]: {
                paddingTop: '8px',
                paddingBottom: '7px'
            },
            [theme.breakpoints.only('md')]: {
                paddingTop: '12px',
                paddingBottom: '11px'
            },
            [theme.breakpoints.only('lg')]: {
                paddingTop: '16px',
                paddingBottom: '15px'
            },
            [theme.breakpoints.only('xl')]: {
                paddingTop: '24px',
                paddingBottom: '22px'
            }
        },
        title: {
            [theme.breakpoints.only('xs')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('sm')]: {
                fontSize: '9px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '11px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '12px'
            }
        },
        option: {
            [theme.breakpoints.only('xs')]: {
                marginTop: '7px'
            },
            [theme.breakpoints.only('sm')]: {
                marginTop: '7px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '11px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '15px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '22px'
            }
        },
        firstOption: {
            marginTop: '0px'
        },
        decline: {
            //color: "#EF4F57"
        }
    })
);

export const BookingOptions: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const history = useHistory();

    const rescheduleOption = (
        <ToolbarItem
            id="booking_reschedule_"
            icon={faCalendarAlt}
            text="Edit Booking"
            onClick={() => props.onReschedule && props.onReschedule()}
        />
    );

    const rebookOption = (
        <ToolbarItem
            id="rebook_booking_"
            icon={faRedo}
            text={'Rebook'}
            onClick={() => props.onRebook && props.onRebook()}
        />
    );

    const cancelOption = (
        <ToolbarItem
            id="booking_cancel_"
            icon={faTimesCircle}
            isDetail={props.isDetail}
            text="Cancel"
            color="#EF4F57"
            onClick={() => props.onCancel && props.onCancel()}
        />
    );

    const noShowOption = (
        <NoShowToolbarItem
            isDetail={props.isDetail}
            onConfirmedClick={() => props.onNoShow && props.onNoShow()}
        />
    );

    const hasReportsPremiumAccess = useHasPremiumAccess('groomming_reports');

    const addReportOption = (
        <Box className={classes.decline}>
            <ToolbarItem
                id="appointment_add_report_"
                icon={faFile}
                text={'Add Report'}
                onClick={() =>
                    hasReportsPremiumAccess
                        ? props.onShowAddReport && props.onShowAddReport()
                        : history.push(BusinessPlanPath)
                }
            />
        </Box>
    );

    const reportOption = (
        <ToolbarItem
            id="appointment_report_"
            icon={faFileSearch}
            text={'View Report'}
            onClick={() =>
                hasReportsPremiumAccess ? props.onShowReport && props.onShowReport() : history.push(BusinessPlanPath)
            }
        />
    );

    const showReport = [
        status.READY_FOR_CHECK_OUT,
        status.PAYMENT,
        status.COMPLETED,
        status.DECLINED,
        status.WAITING_PAYMENT_CONFIRMATION,
        status.IN_PROGRESS,
        status.BOOKED,
        status.CONFIRMED,
        status.CHECKED_IN
    ];

    return (
        <Box className={classes.container}>
            <ToolbarButton id="booking_options">
                {!props.withoutReschedule && !props.booking?.appointments[0].pet.deceased && rescheduleOption}
                {props.isDetail ? (
                    <>
                        {!props.customerDeleted && props.singleBooking && props.onRebook && rebookOption}
                        {noShowOption}
                        {cancelOption}
                    </>
                ) : (
                    <>
                        {cancelOption}
                        {!props.booking?.appointments[0].pet.deceased && noShowOption}
                        {!props.customerDeleted &&
                            hasReportsPremiumAccess &&
                            !props.isDetail &&
                            props.booking?.appointments[0]?.services &&
                            props.booking?.appointments[0]?.services?.length > 0 &&
                            props.booking?.appointments[0]?.services[0]?.hasReport &&
                            props.booking?.appointments[0]?.reports?.dentalReportId == null &&
                            props.booking?.appointments[0]?.reports?.groomingReportId == null &&
                            !props.booking?.appointments[0]?.pet.deceased &&
                            addReportOption}
                        {hasReportsPremiumAccess &&
                            !props.isDetail &&
                            props.booking?.appointments[0]?.services &&
                            props.booking?.appointments[0]?.services?.length > 0 &&
                            (props.booking?.appointments[0]?.reports?.dentalReportId ||
                                props.booking?.appointments[0]?.reports?.groomingReportId) &&
                            showReport.includes(props.booking?.appointments[0]?.status.id) &&
                            reportOption}
                    </>
                )}
            </ToolbarButton>
        </Box>
    );
};

export default BookingOptions;
