import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { wbp, reduceResolution } from 'Theme';

export const useCommonStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'block',
            justifyContent: 'center',
            background: 'white',
            height: '100%',
            width: '100%'
        },
        iframe: {
            width: '100%',
            height: '100%',
            border: 'none'
        },
        contentInsights: {
            width: '100%',
            height: '100%',
            border: 'none',
            padding: '25px',
            paddingTop: '45px'
        },
        titleDefault: {
            fontSize: '24px',
            fontWeight: 600,
            marginBottom: '25px',
            marginTop: '0px'
        },
        cardsContent: {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            gap: '20px'
        },
        subViewTitle: {
            'display': 'flex',
            'alignItems': 'center',
            'marginBottom': '30px',
            '& h2': {
                marginBottom: '0px'
            }
        },
        backIcon: {
            cursor: 'pointer',
            marginRight: '8px'
        }
    })
);
