import { faDashboard, faUser } from '@fortawesome/pro-regular-svg-icons';

export const Categories = {
    DASHBOARD: 'Dashboard',
    CLIENTS_AND_PETS: 'Clients & Pets'
};

export const CategoryData = [
    {
        category: Categories.DASHBOARD,
        data: [
            {
                icon: faDashboard,
                title: Categories.DASHBOARD,
                key: 'insights'
            }
        ]
    },
    {
        category: Categories.CLIENTS_AND_PETS,
        data: [
            {
                icon: faUser,
                title: Categories.CLIENTS_AND_PETS,
                key: 'clients-and-pets'
            }
        ]
    }
];
