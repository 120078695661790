import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

export interface MultiSlotDatePickerOption<T> {
    label: string;
    value: T;
}

export interface MultiSlotDatePickerProps<T> {
    options: MultiSlotDatePickerOption<T>[];
    selectedOption?: T;
    isSelected?: (option: MultiSlotDatePickerOption<T>) => boolean;
    onDateChange: (value: T) => void;
}

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            'display': 'flex',
            'paddingTop': 20,
            'paddingLeft': 16,
            'paddingRight': 16,
            'margin': '0px -16px',
            'overflowX': 'auto',
            'overflowY': 'hidden',
            'justifyContent': 'space-between',
            'borderBottom': 'solid 1px #D4D4D4',

            '-ms-overflow-style': 'none',
            'scrollbar-width': 'none',
            '&::-webkit-scrollbar': {
                display: 'none'
            },

            [theme.breakpoints.up('md')]: {
                margin: 0,
                width: '100%',
                paddingLeft: 0,
                paddingRight: 0
            }
        },
        dateButton: {
            'border': 0,
            'fontSize': 16,
            'fontWeight': 500,
            'whiteSpace': 'nowrap',
            'fontFamily': 'Poppins',
            'color': '#BCB8AE',
            'appearance': 'none',
            'marginBottom': -1,
            'paddingBottom': 14,
            'background': 'transparent',
            'borderBottom': 'solid 2px transparent',
            'cursor': 'pointer',

            '&[data-active="true"]': {
                color: '#000000',
                borderBottomColor: '#000000'
            }
        }
    })
);

export const MultiSlotDatePicker = <T,>(props: MultiSlotDatePickerProps<T>) => {
    const classes = useStyles();

    const isSelected = (option: MultiSlotDatePickerOption<T>) => {
        return props.isSelected ? props.isSelected(option) : props.selectedOption === option.value;
    };

    return (
        <div className={classes.root}>
            {props.options.map((item, i) => (
                <button
                    key={i}
                    type="button"
                    className={classes.dateButton}
                    id={`add-slot-date-picker-${i}`}
                    data-active={isSelected(item)}
                    onClick={() => props.onDateChange(item.value)}
                >
                    {item.label}
                </button>
            ))}
        </div>
    );
};
