import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { CounterField } from 'components/UI/V2/CounterField/CounterField';
import { DropdownSelect, DropdownSelectOption } from 'components/UI/V2/DropdownSelect/DropdownSelect';
import React from 'react';

export type SlotFieldProps = SlotFieldOnlyLabelProps | SlotFieldWithHoursProps;

type SlotFieldOnlyLabelProps = {
    showLabel: true;
};

type SlotFieldWithHoursProps = {
    time?: string;
    petsCount?: number;
    showLabel?: false;
    readOnly?: boolean;
    selectedHours?: Array<string>;
    hours: Array<DropdownSelectOption<string>>;
    onChange?: (time?: string, petsCount?: number) => void;
    onDelete?: () => void;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            'gap': 8,
            'width': '100%',
            'display': 'grid',
            'gridTemplateColumns': '115px 90px 1fr 25px',
            'alignItems': 'center',
            'borderBottom': 'solid 1px #D4D4D4',

            '&[data-show-label="true"]': {
                paddingBottom: 0,
                borderBottom: 'none'
            },
            '&:not([data-show-label="true"])': {
                height: 72
            },

            [theme.breakpoints.up('md')]: {
                gap: 28
            }
        },
        label: {
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 500
        },
        deleteButton: {
            border: 0,
            appearance: 'none',
            background: 'none',
            cursor: 'pointer'
        },
        hidden: {
            visibility: 'hidden',
            pointerEvents: 'none'
        },
        dropdown: {
            '& label': {
                minWidth: '4.25em'
            }
        }
    })
);

export const SlotField: React.FC<SlotFieldProps> = props => {
    const classes = useStyles();

    if (props.showLabel) {
        return (
            <div
                className={classes.root}
                data-show-label={props.showLabel}
            >
                <Typography className={classes.label}>Time</Typography>
                <div></div>
                <Typography className={classes.label}>Pets</Typography>
            </div>
        );
    }

    const isReadOnly = !!props.readOnly;

    const timeChangeHandler = (value: string) => {
        props.onChange?.(value, props.petsCount);
    };

    const petsCountChangeHandler = (value: number) => {
        props.onChange?.(props.time, value);
    };

    return (
        <div
            className={classes.root}
            data-show-label={props.showLabel}
        >
            <DropdownSelect<string>
                id="slot-field-time-dropdown"
                label="Time"
                selectFirstOption
                value={props.time}
                readOnly={isReadOnly}
                disabledOptions={props.selectedHours}
                className={classes.dropdown}
                onChange={timeChangeHandler}
                options={props.hours}
            />

            <Typography className={classes.label}>can accept</Typography>

            {/* Pets count */}
            <CounterField
                minLimit={1}
                value={props.petsCount}
                readOnly={isReadOnly}
                onChange={petsCountChangeHandler}
            />

            {/* Actions */}
            {!isReadOnly && (
                <button
                    type="button"
                    id="slot-field-delete-button"
                    className={classes.deleteButton}
                    onClick={props.onDelete}
                >
                    <FontAwesomeIcon icon={faTrashAlt} />
                </button>
            )}
        </div>
    );
};
