import React, { FunctionComponent } from 'react';
import { Box, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSparkles } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

interface CardProps {
    title: string;
    subTitle: string;
    description: string;
    premium?: boolean;
    icon: IconDefinition;
    onClick: () => void | undefined;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            'position': 'relative',
            'border': '1px solid #D4D4D4',
            'borderRadius': '12px',
            'padding': theme.spacing(2),
            'flex': '0 1 calc(50%)',
            'maxWidth': 'calc(50% - 10px)',
            'minHeight': '112px',
            'height': 'auto',
            'transition': 'border-color 0.3s ease',
            '&:hover': {
                borderColor: '#000000'
            },
            [theme.breakpoints.down('sm')]: {
                flex: '1 1 100%',
                maxWidth: '100%',
                minHeight: 'auto'
            }
        },
        badge: {
            display: 'inline-block',
            backgroundColor: '#ccc',
            borderRadius: '15px',
            padding: '6px 10px',
            position: 'absolute',
            top: '16px',
            right: '16px',
            color: '#000000',
            fontSize: '14px',
            fontWeight: 600,
            [theme.breakpoints.down(1200)]: {
                position: 'relative',
                marginTop: '12px',
                top: 'auto',
                right: 'auto'
            }
        },
        premium: {
            backgroundColor: '#FAEFDF'
        },
        icon: {
            marginRight: '5px',
            fontSize: '15px',
            fontWeight: 'bold'
        },
        cardTitle: {
            fontSize: '16px',
            fontWeight: 600,
            color: '#000000',
            marginBottom: '5px'
        },
        cardSubTitle: {
            fontWeight: 500,
            color: '#808080'
        },
        cardDescription: {
            fontSize: '15px',
            fontWeight: 400,
            color: '#000000',
            maxWidth: '75%',
            [theme.breakpoints.down(1200)]: {
                maxWidth: '100%'
            }
        }
    })
);

const Card: FunctionComponent<CardProps> = ({ title, subTitle, description, premium, icon, onClick }) => {
    const classes = useStyles();

    return (
        <Box className={classes.card} onClick={onClick} style={{ cursor: 'pointer' }}>
            <Typography variant="h6" className={classes.cardTitle}>
                <FontAwesomeIcon icon={icon} className={classes.icon} />
                {title} · <span className={classes.cardSubTitle}>{subTitle}</span>
            </Typography>
            <Typography className={classes.cardDescription}>{description}</Typography>
            {premium && (
                <span className={clsx(classes.badge, classes.premium)}>
                    <FontAwesomeIcon className={classes.icon} icon={faSparkles} />
                    Premium
                </span>
            )}
        </Box>
    );
};

export default Card;
