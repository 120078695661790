import { DayHeaderContentArg } from '@fullcalendar/core';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Appointment from '@spike/appointment-model';
import { MarketplaceScheduleType } from '@spike/marketplace-model';
import clsx from 'clsx';
import { PetsCountBadge } from 'components/MultiSlot/PetsCountBadge';
import { getStaffSlotsByDate } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { useMarketplace, useTimeZone } from 'hooks';
import Staff from 'model/Staff';
import moment from 'moment-timezone';
import { FunctionComponent } from 'react';
import { getDay, getWeekdayName } from 'utils/DateUtils';

export interface DayHeaderFullCalendarProps extends DayHeaderContentArg {
    staffs: Array<Staff>;
    appointments: Array<Appointment>;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 8,
            display: 'flex',
            alignItems: 'center'
        },
        dateInfo: {},
        headerWeekday: {
            fontFamily: 'Poppins',
            fontWeight: 500,
            color: '#222222',
            [theme.breakpoints.down('sm')]: {
                fontSize: '8px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '10px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '12px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '15px'
            }
        },
        headerDay: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: '#222222',
            [theme.breakpoints.down('sm')]: {
                fontSize: '9px',
                width: '18px',
                height: '18px',
                borderRadius: '18px'
            },
            [theme.breakpoints.only('md')]: {
                fontSize: '12px',
                width: '23px',
                height: '23px',
                borderRadius: '23px'
            },
            [theme.breakpoints.only('lg')]: {
                fontSize: '18px',
                width: '30px',
                height: '30px',
                borderRadius: '30px'
            },
            [theme.breakpoints.only('xl')]: {
                fontSize: '22px',
                width: '42px',
                height: '42px',
                borderRadius: '42px'
            }
        },
        headerToday: {
            backgroundColor: '#BDD2CA'
        }
    })
);

export const DayHeaderFullCalendar: FunctionComponent<DayHeaderFullCalendarProps> = props => {
    const classes = useStyles();
    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const date = moment(props.date).tz(timeZone);

    const appointmentsCount = props.appointments.filter(appointment =>
        appointment.duration.from.isSame(date, 'day')
    ).length;

    const maxSlots = props.staffs.reduce((acc, staff) => {
        const staffslots = getStaffSlotsByDate(staff.slots, date).map(slot => slot.petsCount);

        return acc + staffslots.reduce((acc, slot) => acc + slot, 0);
    }, 0);

    return (
        <div className={classes.root}>
            <div className={classes.dateInfo}>
                <Box className={classes.headerWeekday}>{getWeekdayName(props.date, timeZone)}</Box>
                <Box
                    className={clsx(classes.headerDay, {
                        [classes.headerToday]: props.isToday
                    })}
                >
                    {getDay(props.date, timeZone)}
                </Box>
            </div>

            {marketplace.scheduleType === MarketplaceScheduleType.MultiSlots && (
                <PetsCountBadge
                    maxPetsCount={maxSlots}
                    petsCount={appointmentsCount}
                />
            )}
        </div>
    );
};

export default DayHeaderFullCalendar;
