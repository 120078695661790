import { createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            'gap': 14,
            'width': '100%',
            'display': 'flex',
            'marginTop': 'auto',
            'padding': '16px 30px',
            'backgroundColor': '#ffffff',
            'borderTop': '1px solid #D4D4D4',

            '& > button': {
                height: 47,
                width: '100%'
            }
        }
    })
);

export const MultiSlotDrawerFooter: React.FC<React.PropsWithChildren<{ className?: string }>> = props => {
    const classes = useStyles();

    return <div className={clsx(classes.root, props.className)}>{props.children}</div>;
};
