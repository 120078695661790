import { EventClickArg, EventDropArg, EventSourceInput } from '@fullcalendar/core';
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction';
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import FullCalendar from '@fullcalendar/react';
import { ResourceSourceInput } from '@fullcalendar/resource';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import { Box } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Appointment from '@spike/appointment-model';
import { BlockCalendar } from '@spike/block-calendar-model';
import { useTimeZone } from 'hooks';
import { StaffSchedule, StaffSlots } from 'model/Staff';
import moment, { Moment } from 'moment-timezone';
import { FunctionComponent, createRef, useEffect, useMemo } from 'react';
import {
    convertToBlockCalendarEvent,
    convertToEvent,
    convertToTempEvent,
    getResourceBusinessHours,
    renderEvent
} from '../FullCalendar/FullCalendarUtils';
import NowIndicatorFullCalendar from '../FullCalendar/NowIndicatorFullCalendar';
import { useFullCalendarStyles } from '../FullCalendar/StylesFullCalendar';
import { TempNewBooking } from '../FullCalendar/model';
import ResourceHeaderFullCalendar from './ResourceHeaderFullCalendar';

interface DayFullCalendarProps {
    date: Moment;
    appointments: Array<Appointment>;
    blocks: Array<BlockCalendar>;
    multipleBookingsIds: Array<number>;
    staff: Array<StaffResource>;
    tempNewBooking?: TempNewBooking;
    loadingAppointmentIds?: Array<number>;
    onClickAppointment?: (id: number) => void;
    onClickBlockCalendar?: (id: number) => void;
    onRescheduled?: (appointmentId: number, at: Moment, revert: () => void, staffId?: number) => void;
    onRescheduledBlocked?: (blocked: BlockCalendar, revert: () => void) => void;
    onBook?: (at: Moment, staffId?: number) => void;
}

export interface StaffResource {
    id: number;
    firstName: string;
    lastName: string;
    avatar: string;
    schedule: StaffSchedule;
    slots: StaffSlots;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            'minWidth': '100%',
            '& .fc .fc-timegrid-slot': {
                [theme.breakpoints.down('sm')]: {
                    height: '22px !important'
                },
                [theme.breakpoints.only('md')]: {
                    height: '33px !important'
                },
                [theme.breakpoints.only('lg')]: {
                    height: '42px !important'
                },
                [theme.breakpoints.only('xl')]: {
                    height: '49px !important'
                }
            },
            '& .fc .fc-timegrid-slot-label': {
                border: 'none !important'
            },
            '& .fc-scrollgrid-section-body.fc-scrollgrid-section-liquid > td:first-child .fc-scroller': {
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none'
            },
            '& .fc-scrollgrid-section.fc-scrollgrid-section-header > th:last-child .fc-scroller': {
                '&::-webkit-scrollbar': {
                    display: 'none'
                },
                '-ms-overflow-style': 'none',
                'scrollbar-width': 'none'
            },
            '& .fc-col-header-cell-cushion': {
                padding: 0
            }
        },
        resourceHeader: {
            backgroundColor: 'white',
            borderTop: 'none !important',
            borderLeft: 'none !important',
            borderRight: 'none !important',
            borderBottom: '1px solid #D8D8D8 !important',
            verticalAlign: 'middle !important',
            border: '1px solid red !important'
        },
        nowIndicator: {
            '&.fc-timegrid-now-indicator-arrow': {
                border: 'none',
                width: '100%'
            },
            '&.fc-timegrid-now-indicator-line': {
                border: '1px solid black !important',
                height: '0px',
                backgroundColor: 'black'
            }
        }
    })
);

export const DayFullCalendar: FunctionComponent<DayFullCalendarProps> = props => {
    const classes = useStyles();
    const fullcalendarClasses = useFullCalendarStyles();
    const timeZone = useTimeZone();

    const fullCalendarRef = createRef<FullCalendar>();

    useEffect(() => {
        fullCalendarRef.current?.getApi().gotoDate(props.date.toDate());
    }, [props.date]);

    useEffect(() => {
        props.tempNewBooking &&
            fullCalendarRef.current?.getApi().scrollToTime({ hour: props.tempNewBooking?.at.get('hour') });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.tempNewBooking]);

    const today = moment().tz(timeZone);

    const scrollTime = today.format('HH:00:00');

    const events: EventSourceInput = useMemo(() => {
        const events = props.appointments.map(appointment =>
            convertToEvent(appointment, timeZone, 'daily', props.multipleBookingsIds)
        );

        const blocks = props.blocks.map(block => convertToBlockCalendarEvent(block, timeZone));

        return props.tempNewBooking
            ? [...events, ...blocks, convertToTempEvent(props.tempNewBooking, timeZone)]
            : [...events, ...blocks];
    }, [props.appointments, props.blocks, props.tempNewBooking, timeZone]);

    const resources: ResourceSourceInput = useMemo(
        () =>
            props.staff.map(member => ({
                id: member.id.toString(),
                businessHours: getResourceBusinessHours(props.date, member.schedule),
                title: `${member.firstName} ${member.lastName}`,
                firstName: member.firstName,
                lastName: member.lastName,
                avatar: member.avatar,
                slots: member.slots,
                appointmentsCount: props.appointments.filter(
                    appointment =>
                        appointment.duration.from.isSame(props.date, 'day') &&
                        appointment.services.some(service => service.staff?.id === member.id)
                ).length
            })),
        [props.appointments, props.date, props.staff]
    );

    const eventClickHandler = (args: EventClickArg) => {
        args.event.extendedProps.type === 'appointment' &&
            props.onClickAppointment &&
            props.onClickAppointment(args.event.extendedProps.appointmentId);

        args.event.extendedProps.type === 'block' &&
            props.onClickBlockCalendar &&
            props.onClickBlockCalendar(args.event.extendedProps.blockId);
    };

    const dropHandler = (args: EventDropArg, timeZone: string) => {
        if (args.event.extendedProps.type === 'block') {
            const blockedTime: BlockCalendar = props.blocks.find(
                block => block.id === args.event.extendedProps.blockId
            )!;
            blockedTime.start = moment(args.event.start).tz(timeZone);
            blockedTime.end = moment(args.event.end).tz(timeZone);
            if (args.newResource?.id) {
                const newStaff = props.staff.find(staffAvailable => staffAvailable.id === Number(args.newResource?.id));
                blockedTime.staff = newStaff
                    ? {
                          id: newStaff?.id ?? 0,
                          firstName: newStaff?.firstName ?? '',
                          lastName: newStaff?.lastName ?? '',
                          image: newStaff?.avatar ?? ''
                      }
                    : blockedTime.staff;
            }
            props.onRescheduledBlocked && props.onRescheduledBlocked(blockedTime, args.revert);
        } else {
            props.onRescheduled &&
                props.onRescheduled(
                    args.event.extendedProps.appointmentId,
                    moment(args.event.start).tz(timeZone),
                    args.revert,
                    args.newResource?.id ? Number(args.newResource?.id) : undefined
                );
        }
    };

    const dateClickHandler = (args: DateClickArg, timeZone: string) => {
        const at = moment(args.date).tz(timeZone);
        const quarter = Math.trunc(at.minutes() / 15);
        at.minutes(quarter * 15);
        props.onBook && props.onBook(at, args.resource?.id !== undefined ? Number(args.resource?.id) : undefined);
    };

    const fullCalendarComponent = useMemo(
        () => (
            <FullCalendar
                ref={fullCalendarRef}
                schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
                plugins={[scrollGridPlugin, momentTimezonePlugin, resourceTimeGridPlugin, interactionPlugin]}
                initialView="resourceTimeGridDay"
                timeZone={timeZone}
                editable={true}
                eventStartEditable={props.loadingAppointmentIds?.length === 0}
                eventDurationEditable={false}
                slotDuration="0:15:00"
                slotLabelInterval="1:00:00"
                snapDuration="0:10:00"
                expandRows={true}
                height="100%"
                headerToolbar={false}
                allDaySlot={false}
                stickyHeaderDates={false}
                dayCellClassNames={[fullcalendarClasses.dayCell]}
                slotLabelFormat={{
                    hour: 'numeric',
                    omitZeroMinute: false
                }}
                slotLabelClassNames={[fullcalendarClasses.slotLabel]}
                slotLaneClassNames={[fullcalendarClasses.slotLane]}
                nowIndicator={true}
                nowIndicatorClassNames={[classes.nowIndicator]}
                nowIndicatorContent={props => <NowIndicatorFullCalendar {...props} />}
                scrollTime={scrollTime}
                events={events}
                eventContent={event => renderEvent(event, props.loadingAppointmentIds)}
                eventClassNames={[fullcalendarClasses.event]}
                eventClick={eventClickHandler}
                eventDrop={args => dropHandler(args, timeZone)}
                dateClick={args => dateClickHandler(args, timeZone)}
                resources={resources}
                resourceOrder="title"
                resourceLabelContent={args => (
                    <ResourceHeaderFullCalendar
                        {...args}
                        date={props.date}
                    />
                )}
                resourceLabelClassNames={[classes.resourceHeader]}
                dayMinWidth={200}
            />
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [events, resources]
    );

    return <Box className={classes.container}>{fullCalendarComponent}</Box>;
};

export default DayFullCalendar;
