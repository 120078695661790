import { useAuth } from 'hooks';
import { FunctionComponent, useMemo, useState } from 'react';
import PrivatePage from './PrivatePage';
import PageSidebar from 'components/PageSidebar';
import ClientsAndPets from 'components/Insights/ClientsAndPets/ClientsAndPets';
import Dashboard from 'components/Insights/Dashboard/Dashboard';
import { CategoryData, Categories } from 'components/Insights/Data/InsightStructure';

export const Insights: FunctionComponent = () => {
    const auth = useAuth();

    const insightsUrl = `${process.env.REACT_APP_HOST_URL}${process.env.REACT_APP_WEB_BASE_URL}stats?access_token=${auth.token}&marketplace_id=${auth.marketplaceId}`;

    document.title = 'Insights - Cuddles';

    const sidebarItems = useMemo(() => {
        return CategoryData.flatMap(category => category.data);
    }, []);

    const [selectedKey, setSelectedKey] = useState<string>(sidebarItems[0].key);

    const sidebar = (
        <PageSidebar title="Insights" items={sidebarItems} selectedKey={selectedKey} onSelect={setSelectedKey} />
    );

    const dashboard = (
        <PrivatePage title={`${Categories.DASHBOARD} - Cuddles`} pageName="/insights" pageSidebar={sidebar}>
            <Dashboard title={Categories.DASHBOARD} insightsUrl={insightsUrl} />
        </PrivatePage>
    );

    const clientsAndPets = (
        <PrivatePage title={Categories.CLIENTS_AND_PETS} pageName="/insights/clients" pageSidebar={sidebar}>
            <ClientsAndPets category={Categories.CLIENTS_AND_PETS} title={Categories.CLIENTS_AND_PETS} />
        </PrivatePage>
    );

    switch (selectedKey) {
        case 'clients-and-pets':
            return clientsAndPets;
        default:
            return dashboard;
    }
};

export default Insights;
