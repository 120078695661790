import React, { FunctionComponent } from 'react';
import { Box, Typography, createStyles, makeStyles, Theme } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-regular-svg-icons';

interface Props {
    title: string;
    insightsUrl: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'block',
            justifyContent: 'center',
            background: 'white',
            height: '100%',
            width: '100%'
        },
        contentInsights: {
            marginRight: theme.spacing(1)
        },
        titleDefault: {
            fontSize: '1.5rem',
            fontWeight: 'bold'
        },
        iframe: {
            width: '100%',
            height: '100%',
            border: 'none'
        }
    })
);

const Dashboard: FunctionComponent<Props> = ({ title, insightsUrl }) => {
    const classes = useStyles();

    return (
        <Box className={classes.container}>
            <iframe title="Insights" className={classes.iframe} src={insightsUrl}></iframe>
        </Box>
    );
};

export default Dashboard;
