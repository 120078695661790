import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useCounterFieldStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            gap: 4,
            display: 'inline-flex',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                gap: 14
            }
        },
        label: {
            fontSize: 15,
            lineHeight: 1,
            fontWeight: 500,
            textAlign: 'center',
            minWidth: '1.85em'
        },
        button: {
            border: 0,
            fontSize: 16,
            padding: 0,
            cursor: 'pointer',
            appearance: 'none',
            background: 'none'
        },
        hidden: {
            display: 'none'
        }
    })
);
