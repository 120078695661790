import React, { FunctionComponent, useState } from 'react';
import { Box } from '@material-ui/core';
import { useCommonStyles } from '../CommonStyles';
import Card from '../UI/Card';
import { cards, CardData, CardTitles } from '../Data/CardData';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasPremiumAccess, useHasPremiumPlan } from 'hooks/premiumPlan';

interface Props {
    title: string;
    category: string;
}

const ClientsAndPets: FunctionComponent<Props> = ({ title }) => {
    const commonClasses = useCommonStyles();
    const hasPremiumPlan = useHasPremiumPlan();

    const [selectedCard, setSelectedCard] = useState<CardData | null>(null);

    const handleCardClick = (card: CardData) => {
        setSelectedCard(card);
    };

    const showDefaultView = () => {
        setSelectedCard(null);
    };

    const renderContent = () => {
        if (!selectedCard) {
            return (
                <Box display="flex" flexWrap="wrap" className={commonClasses.cardsContent}>
                    {cards.map((card, index) => (
                        <Card
                            key={index}
                            title={card.title}
                            subTitle={card.category}
                            description={card.description}
                            premium={card.premium}
                            icon={card.icon}
                            onClick={() => {
                                if (!card.premium || hasPremiumPlan) {
                                    handleCardClick(card);
                                }
                            }}
                        />
                    ))}
                </Box>
            );
        }

        // Return different components based on selected Card
        switch (selectedCard.title) {
            case CardTitles.CLIENT_LIST:
                return <div>Client List Component</div>;
            case CardTitles.VACCINE_STATUS:
                return <div>Vaccine Status Component</div>;
            case CardTitles.PET_BIRTHDAY:
                return <div>Pet Birthday Component</div>;
            case CardTitles.CLIENT_INSIGHTS:
                return <div>Client Insights Component</div>;
            case CardTitles.PET_BREAKDOWN:
                return <div>Pet Breakdown Component</div>;
            default:
                return null;
        }
    };

    return (
        <Box className={commonClasses.container}>
            <Box className={commonClasses.contentInsights}>
                <Box className={commonClasses.subViewTitle}>
                    {selectedCard && (
                        <FontAwesomeIcon
                            className={commonClasses.backIcon}
                            icon={faArrowLeft}
                            onClick={showDefaultView}
                        />
                    )}
                    <h2 className={commonClasses.titleDefault}>{selectedCard ? selectedCard.title : title}</h2>
                </Box>
                {renderContent()}
            </Box>
        </Box>
    );
};

export default ClientsAndPets;
