import React from 'react';
import { createStyles, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Period } from '@spike/model';
import Button from 'components/UI/V2/Button/Button';
import { faCalendarDays } from '@fortawesome/pro-light-svg-icons';

interface ScheduleTypeCalendarToolbarProps {
    selectedWeek: Period;
    onGoToToday?: () => void;
    onSetupDefaultSlots?: () => void;
    onChange?: (week: Period) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            padding: 0,
            width: '100%',
            marginBottom: 32,

            [theme.breakpoints.up('sm')]: {
                padding: '0px 40px'
            }
        },
        root: {
            width: '100%',
            borderRadius: 12,
            padding: '8px 16px',
            backgroundColor: '#F1F1F1',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',

            [theme.breakpoints.up('sm')]: {
                padding: '13px 16px'
            },
            [theme.breakpoints.up('lg')]: {
                display: 'grid',
                justifyContent: 'center',
                gridTemplateColumns: 'minmax(0, 205px) minmax(0, 3fr) minmax(0, 205px)'
            }
        },
        currentWeekButton: {
            fontSize: 14,
            lineHeight: 1.5,
            fontWeight: 500,
            borderRadius: 30,
            cursor: 'pointer',
            padding: '6px 15px',
            fontFamily: 'Poppins',
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #000000',

            [theme.breakpoints.up('sm')]: {
                fontSize: 16
            }
        },
        calendarControlsContainer: {
            gap: 8,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        calendarControls: {
            'gap': 2,
            'display': 'flex',
            'alignItems': 'center',

            '& p': {
                margin: 0,
                fontSize: 14,
                lineHeight: 1,
                fontWeight: 600,
                fontFamily: 'Poppins',

                [theme.breakpoints.up('sm')]: {
                    fontSize: 18
                }
            },
            '& button': {
                outline: 0,
                fontSize: 14,
                border: 'none',
                appearance: 'none',
                background: 'none',
                cursor: 'pointer'
            }
        },
        desktop: {
            [theme.breakpoints.down('sm')]: {
                display: 'none'
            }
        },
        mobile: {
            [theme.breakpoints.up('md')]: {
                display: 'none'
            }
        },
        fakeColumn: {
            [theme.breakpoints.down('md')]: {
                display: 'none'
            }
        }
    })
);

export const ScheduleTypeCalendarToolbar: React.FC<ScheduleTypeCalendarToolbarProps> = props => {
    const classes = useStyles();

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const changeWeek = (days: number) => {
        props.onChange?.({
            from: props.selectedWeek.from.clone().add(days, 'days'),
            to: props.selectedWeek.to.clone().add(days, 'days')
        });
    };

    return (
        <div className={classes.container}>
            <div className={classes.root}>
                <div className={classes.fakeColumn}></div>
                <div className={classes.calendarControlsContainer}>
                    <button
                        type="button"
                        id="schedule_type_calendar_this_week_button"
                        className={classes.currentWeekButton}
                        onClick={() => props.onGoToToday?.()}
                    >
                        This Week
                    </button>
                    <div className={classes.calendarControls}>
                        <button
                            type="button"
                            id="schedule_type_calendar_previous_week_button"
                            onClick={() => changeWeek(-7)}
                        >
                            <FontAwesomeIcon icon={faCaretLeft} />
                        </button>
                        <p>
                            <span className={classes.desktop}>{props.selectedWeek.from.format('MMMM ')}</span>
                            <span className={classes.mobile}>{props.selectedWeek.from.format('MMM ')}</span>
                            {props.selectedWeek.from.format('D ')}- {props.selectedWeek.to.format('D, YYYY')}
                        </p>
                        <button
                            type="button"
                            id="schedule_type_calendar_next_week_button"
                            onClick={() => changeWeek(7)}
                        >
                            <FontAwesomeIcon icon={faCaretRight} />
                        </button>
                    </div>
                </div>
                <div>
                    <Button
                        variant="green"
                        onlyIcon={isMobile}
                        leftIcon={faCalendarDays}
                        label="Setup Default Slots"
                        id="add-slot-setup-default-slots-button"
                        onClick={props.onSetupDefaultSlots}
                    />
                </div>
            </div>
        </div>
    );
};
