import { createStyles, Drawer, makeStyles, Theme } from '@material-ui/core';
import React from 'react';

export type MultiSlotDrawerProps = React.PropsWithChildren<{
    open: boolean;
    onClose: () => void;
}>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            '& .MuiDrawer-paperAnchorRight': {
                width: '100%',

                [theme.breakpoints.up('md')]: {
                    width: 590
                }
            }
        },
        wrapper: {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden'
        }
    })
);

export const MultiSlotDrawer: React.FC<MultiSlotDrawerProps> = props => {
    const classes = useStyles();

    return (
        <Drawer
            anchor="right"
            open={props.open}
            onClose={props.onClose}
            className={classes.root}
        >
            <div className={classes.wrapper}>{props.children}</div>
        </Drawer>
    );
};
