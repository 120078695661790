import { Box } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { FunctionComponent } from 'react';
import clsx from 'clsx';

import { BookingButton } from 'components/UI';
import Appointment, { status } from '@spike/appointment-model';
import { AppointmentButton } from 'components/UI/AppointmentButton';

interface BookingActionButtonProps {
    appointment: Appointment;
    className?: string;
    loadingAction?: boolean;
    isUndo?: boolean;
    //
    onConfirm?: () => void;
    onComplete: () => void;
    onCheckIn: (appoitmentId: number) => void;
    onCheckOut: () => void;
    onShowDetails?: () => void;
    onStart?: (appoitmentId: number) => void;
    id?: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {}
    })
);

export const ButtonStatusAction: FunctionComponent<BookingActionButtonProps> = props => {
    const classes = useStyles();

    const onConfirmHandler = () => {
        props.onConfirm && props.onConfirm();
    };

    const checkStaff = () => {
        let checkStaff = false;

        if (props.appointment.services.length > 0)
            props.appointment.services[0].staff === undefined ? (checkStaff = false) : (checkStaff = true);
        else checkStaff = false;

        return checkStaff;
    };

    const onStartHandler = (appointmentId: number) => {
        if (checkStaff()) props.onStart && props.onStart(appointmentId);
    };

    const onCompleteHandler = () => {
        if (checkStaff()) props.onComplete();
    };

    const onCheckOutHandler = () => {
        props.onCheckOut();
    };

    const onShowHandler = () => {
        props.onShowDetails && props.onShowDetails();
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            {props.appointment.status.id === status.BOOKED && (
                <BookingButton
                    id={props.id || ''}
                    loading={props.loadingAction}
                    //
                    onConfirm={onConfirmHandler}
                />
            )}

            {props.appointment.status.id !== status.BOOKED &&
                props.appointment.status.id !== status.READY_FOR_CHECK_OUT &&
                props.appointment.status.id !== status.PAYMENT && (
                    <AppointmentButton
                        id={props.id || ''}
                        appointment={props.appointment}
                        loadingAction={false}
                        isUndo={props.isUndo}
                        showCancelationReason={false}
                        onCheckIn={(appointmentId: number) => props.onCheckIn(appointmentId)}
                        onCheckOut={onCheckOutHandler}
                        onComplete={onCompleteHandler}
                        onShowDetails={onShowHandler}
                        onStart={() => onStartHandler(props.appointment.id!)}
                    />
                )}
        </Box>
    );
};
