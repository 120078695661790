import { faArrowLeft, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import React from 'react';

export type MultiSlotDrawerHeaderProps = React.PropsWithChildren<{
    title: string;
    description?: string;
    label?: string;
    actionButton?: React.ReactNode;
    onBack?: () => void;
    onClose: () => void;
}>;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            padding: '0px 16px',
            backgroundColor: '#ffffff',

            [theme.breakpoints.up('md')]: {
                padding: '0px 30px'
            }
        },
        topBarContainer: {
            height: 66,
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',

            [theme.breakpoints.up('md')]: {
                height: 80
            }
        },
        title: {
            gap: 12,
            fontSize: 22,
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center'
        },
        actionButton: {
            padding: 0,
            fontSize: 20,
            border: 'none',
            cursor: 'pointer',
            appearance: 'none',
            backgroundColor: 'transparent'
        },
        description: {
            fontSize: 15,
            marginBottom: 24
        },
        selectDateContainer: {
            'display': 'flex',
            'justifyContent': 'space-between',
            'alignItems': 'center',

            '& button': {
                fontSize: 14
            }
        },
        selectDateText: {
            fontSize: 16,
            fontWeight: 600
        }
    })
);

export const MultiSlotDrawerHeader: React.FC<MultiSlotDrawerHeaderProps> = props => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.topBarContainer}>
                <Typography className={classes.title}>
                    {props.onBack && (
                        <button
                            type="button"
                            className={classes.actionButton}
                            onClick={props.onBack}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </button>
                    )}

                    {props.title}
                </Typography>

                <button
                    type="button"
                    className={classes.actionButton}
                    id="close-slot-drawer-button"
                    onClick={props.onClose}
                >
                    <FontAwesomeIcon icon={faTimes} />
                </button>
            </div>

            {props.description && <Typography className={classes.description}>{props.description}</Typography>}

            <div className={classes.selectDateContainer}>
                {props.label && <Typography className={classes.selectDateText}>{props.label}</Typography>}

                {props.actionButton}
            </div>

            {props.children}
        </div>
    );
};
