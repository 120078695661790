import { faUser } from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Categories } from './InsightStructure';

export enum CardTitles {
    CLIENT_LIST = 'Client List',
    VACCINE_STATUS = 'Vaccine Status',
    PET_BIRTHDAY = 'Pet Birthday',
    CLIENT_INSIGHTS = 'Client Insights',
    PET_BREAKDOWN = 'Pet Breakdown'
}

export interface CardData {
    title: CardTitles; // Use enum for title
    category: string;
    description: string;
    premium: boolean;
    icon: IconDefinition;
}

export const cards: CardData[] = [
    {
        title: CardTitles.CLIENT_LIST,
        category: Categories.CLIENTS_AND_PETS,
        description: 'A full list of all clients and pets',
        premium: false,
        icon: faUser
    },
    {
        title: CardTitles.VACCINE_STATUS,
        category: Categories.CLIENTS_AND_PETS,
        description: 'Overview of each pet’s current vaccination records',
        premium: true,
        icon: faUser
    },
    {
        title: CardTitles.PET_BIRTHDAY,
        category: Categories.CLIENTS_AND_PETS,
        description: 'A full list of pet birthdays',
        premium: true,
        icon: faUser
    },
    {
        title: CardTitles.CLIENT_INSIGHTS,
        category: Categories.CLIENTS_AND_PETS,
        description: 'Overview of client activity, behavior, and value within a specific time frame',
        premium: true,
        icon: faUser
    },
    {
        title: CardTitles.PET_BREAKDOWN,
        category: Categories.CLIENTS_AND_PETS,
        description: 'A comprehensive breakdown of all pets',
        premium: true,
        icon: faUser
    }
];
